import React, { Component } from 'react';
import { Tabs, Tab, Form, Alert } from 'react-bootstrap';
import BasicDets from './BasicDets/BasicDets';
import DateLocation from './Date&Loc/DateLocation';
import FinalDets from './FinalDets/FinalDets';
import MsgModalBox from '../../../container/MessageModalBox/MsgModalBox';
import axios from 'axios';
import moment from 'moment';
import {APIURL} from '../../../config'
import dd from '../../../manifestdd.json'
import { Redirect } from 'react-router-dom';

class ProjectForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            goHome: false,
            basicDetails: {
                pTitle: this.props.edit ? this.props.project_details.pTitle : "",
                pDesc: this.props.edit ? this.props.project_details.pDesc : "",
                pCustomer: this.props.edit ? this.props.project_details.pCustomer : "",
                pSponsor: this.props.edit ? (!this.props.project_details.pSponsor)?'No Sponsor': this.props.project_details.pSponsor: "No Sponsor",
                // ddd_id: "",
                active_plan_version: this.props.edit ?!!this.props.project_details.active_plan_version?this.props.project_details.active_plan_version:'':"" ,
                pManager: this.props.edit ? (!!this.props.project_details.pManager && this.props.empList.find((emp) => (emp.id === this.props.project_details.pManager)).emp_is_active)?"":this.props.project_details.pManager : ""
            },
            dateLocation: {
                pstrtDate: this.props.edit ? this.props.project_details.pstrtDate : moment().format('YYYY-MM-DDTHH:mm:ss'),
                pEndDate: this.props.edit ? this.props.project_details.pEndDate : moment().format('YYYY-MM-DDTHH:mm:ss'),
                pPrice: this.props.edit ? this.props.project_details.pPrice : "",
                pCurrency: "USD",
                pLoc: []
            },
            finalDetails: {
                selectedEmp: []
            },
            mapPushPins: [],
            key: "basicDetails",
            showModal: false,
            addError: false,
            basicDetails_val: false,
            dateLocation_val: false,
            finalDetails_val: false,
            showAlert: false,
            errorMsg: '',
            isSubmitting: false
        }
        this.onDelete = this.onDelete.bind(this);
        this.onLocDelete = this.onLocDelete.bind(this);
        this.handleSelectedEmp = this.handleSelectedEmp.bind(this);
        this.handleChangeEmp = this.handleChangeEmp.bind(this);
        this.handlebasicDetailsChange = this.handlebasicDetailsChange.bind(this)
        this.handledateLocationChange = this.handledateLocationChange.bind(this)
        this.addProjectToDB = this.addProjectToDB.bind(this)
        this.handleMultipleLoc = this.handleMultipleLoc.bind(this)
    }

    async addProjectToDB(e) {
        const newState = {
            basicDetails: {
                pTitle: "",
                pDesc: "",
                pCustomer: "",
                pSponsor: "No Sponsor",
                // ddd_id: "",
                active_plan_version: "",
                pManager: ""
            },
            dateLocation: {
                pstrtDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                pEndDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                pPrice: "",
                pCurrency: "USD",
                pLoc: []
            },
            finalDetails: {
                selectedEmp: []
            }
        }
        this.setState({ isSubmitting: true })
        e.preventDefault();
        e.persist()
        var data = {
            company_id: this.props.comp_id,
            user_id: this.props.user.emp_id,
            ddd_id: this.props.edit? this.props.project_details.ddd_id:this.props.customerList.find((cus)=> cus.id === +this.state.basicDetails.pCustomer).ddd_id || null ,
            active_plan_version: this.state.basicDetails.active_plan_version,
            projId: !this.props.edit?null:this.props.project_details.projId,
            pTitle: this.state.basicDetails.pTitle,
            pDesc: this.state.basicDetails.pDesc,
            pCustomer: parseInt(this.state.basicDetails.pCustomer),
            pSponsor: (this.state.basicDetails.pSponsor === 'No Sponsor')?'NULL':this.state.basicDetails.pSponsor,
            // pContact: parseInt(this.state.basicDetails.pContact),
            pManager: parseInt(this.state.basicDetails.pManager),
            pstrtDate: this.state.dateLocation.pstrtDate,
            pEndDate: this.state.dateLocation.pEndDate,
            pPrice: this.state.dateLocation.pPrice,
            pCurrency: this.state.dateLocation.pCurrency,
            pLoc: this.state.dateLocation.pLoc,
            selectedEmp: this.props.edit ?  [] : this.state.finalDetails.selectedEmp
        };
        console.log(data)
        let msg = null
        if(this.props.edit){
            msg = await axios.put(APIURL+"/project", data);
            // msg = {data : {code: 500}}
        }else{
            msg = await axios.post(APIURL+"/project", data);
            // msg = {data : {code: 500}}
        }
        console.log(msg.data)
        
        if (msg.data.code === 200) {
            // debugger;
            // console.log(e)
            // if(!this.props.edit){
            //     e.target.reset();
            // }

            if(this.props?.updateProject){
                this.props.updateProject()
                console.log('YES')
                this.setState({ showModal: false })
            } else {
                this.setState({ showModal: true })
            }

            this.setState({ basicDetails: newState.basicDetails })
            this.setState({ dateLocation: newState.dateLocation })
            this.setState({ finalDetails: newState.finalDetails })
            this.setState({ addError: false })

            //activty log
            var sha1 = require('sha1');
            let sessionID=window.localStorage.getItem('Fw4_access_Token')
            var hash = sha1(sessionID);
            var log_data={
                email:this.props.user.email,
                actionType:'New Project',
                actionSubType:data.pTitle,
                sessionID:hash
            }
            msg = await axios.post(APIURL+"/activity_log",log_data );

        } else {
            // debugger
            this.setState({ basicDetails: this.state.basicDetails })
            this.setState({ dateLocation: this.state.dateLocation })
            this.setState({ finalDetails: this.state.finalDetails })
            this.setState({ addError: true })
            this.setState({ showModal: true })
        }

        this.setState({ isSubmitting: false })
    }

    goHome = (e) => {
        this.setState({ addError: false })
        this.setState({ showModal: false })
        this.setState({ showAlert: false })
        this.setState({ basicDetails_val: false })
        this.setState({ dateLocation_val: false })
        this.setState({ finalDetails_val: false })
        this.setState({goHome: true})
    }

    addNew = (e) => {
        if (this.state.addError) {
            this.setState({ showModal: false })
            this.setState({ showAlert: false })
            if(this.props.edit){this.props.history.push("/projects")}
        } else {
            this.setState({ showModal: false })
            this.setState({ showAlert: false })
            this.setState({ addError: false })
            this.setState({ basicDetails_val: false })
            this.setState({ dateLocation_val: false })
            this.setState({ finalDetails_val: false })
            this.setState({ key: "basicDetails" })
            // if(this.props.edit){this.props.navigationHandler(0)}
        }
    }

    onChange = (key) => {

        const conversion = {
            pTitle: 'Project title',
            pDesc: 'Project description',
            active_plan_version: 'Project active plan version',
            pCustomer: 'Customer name',
            pManager: 'Project manager',
            pPrice: 'Price',
            pEmpStatus: 'Employee status',
            pEmpBillRate: 'Bill rate',
            pEmpFrequency: 'Employee frequency',
            pLoc: 'Location'


        }


        const preKey = this.state.key;
        var validation=this.props.isFormValidated(this.state[preKey])
   
        if (!(validation.isValidated)) {
            if (preKey === "basicDetails") {
                this.setState({ basicDetails_val: false })
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            } else if (preKey === "dateLocation") {
                this.setState({ dateLocation_val: false })
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            }else if (preKey === "finalDetails") {
                console.log(validation.missingKey);
                this.setState({ finalDetails_val: false })
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            }
             else {
                this.setState({ finalDetails_val: false })
            }
            this.setState({ showAlert: true })
            this.setState({ key: preKey })
            return false
        } else {
            if (preKey === "basicDetails") {
                this.setState({ basicDetails_val: true })
            } else if (preKey === "dateLocation") {
                this.setState({ dateLocation_val: true })
            } else {
                this.setState({ finalDetails_val: true })
            }
            this.setState({ showAlert: false })
            this.setState({ key })
            return true
        }
    }

    handlebasicDetailsChange(e) {
        let newState = { ...this.state.basicDetails, [e.target.id]: e.target.value }
        if (e.target.id === "pManager") {
            // this.setState({finalDetails: {...this.state.finalDetails, selectedEmp: []}})
            this.handleSelectedEmp(e)
        }
        // if(e.target.id === "pCustomer"){
        //     let dateState = this.state.dateLocation
        //     dateState.pLoc = []
        //     this.setState({dateLocation: dateState})
        //     this.setState({mapPushPins:[]})
        // }
        this.setState({
            basicDetails: newState
        })
    }

    handledateLocationChange(e) {
        // let newState = { ...this.state.dateLocation, [e.target.id]: e.target.value }
        let dateLocation = this.state.dateLocation
        if (e.target.id === 'pstrtDate') {
            if (moment(e.target.value).isBefore(moment().subtract(1, 'd'))) {
                dateLocation.pstrtDate = moment().format('YYYY-MM-DDTHH:mm:ss')
                dateLocation.pEndDate = moment().format('YYYY-MM-DDTHH:mm:ss')
                this.setState({ dateLocation })
                this.setState({ errorMsg: `Please Enter Start Date after ${moment().subtract(1, 'd').format('DD MMM YYYY')}`, showAlert: true })
                return
            } else {
                dateLocation.pstrtDate = e.target.value
            }
        } else if (e.target.id === 'pEndDate') {
            if (!dateLocation.pstrtDate) {
                dateLocation.pEndDate = moment().format('YYYY-MM-DDTHH:mm:ss')
                this.setState({ dateLocation })
                this.setState({ errorMsg: `Please Enter Start Date First`, showAlert: true })
                return
            } else if (dateLocation.pstrtDate && moment(e.target.value).isBefore(moment(dateLocation.pstrtDate))) {
                dateLocation.pEndDate = moment().format('YYYY-MM-DDTHH:mm:ss')
                this.setState({ dateLocation })
                this.setState({ errorMsg: `Please Enter Valid End Date after ${moment(dateLocation.pstrtDate).subtract(1, 'd').format('DD MMM YYYY')}`, showAlert: true })
                return
            } else {
                dateLocation.pEndDate = e.target.value
            }
        } else {
            dateLocation = { ...this.state.dateLocation, [e.target.id]: e.target.value }
        }
        this.setState({ errorMsg: ``, showAlert: false })
        this.setState({ dateLocation })
    }

    handleSelectedEmp(e) {
        // e.persist()
        let chosenEmpId = e.target.value;
        let chosenEmp = this.props.empList.find(({ id }) => id === +chosenEmpId)
        let empExist = this.state.finalDetails.selectedEmp.find(({ empId }) => empId === +chosenEmpId)
        if (e.target.id === "pEmp") {
            e.target.value = ""
        }
        if (!empExist && chosenEmpId !== "") {
            this.setState((prevState, props) => {
                const newEmp = { empName: chosenEmp.employee_name, empId: chosenEmp.id, pEmpStatus: "", pEmpBillRate: "", pEmpCurrency: "USD", pEmpFrequency: "", pEmpAssFromDate: moment().format('YYYY-MM-DDTHH:mm:ss'), pEmpAssToDate: moment().format('YYYY-MM-DDTHH:mm:ss') };
                if (e.target.id === "pManager" && prevState.basicDetails.pManager !== "") {
                    let selectedEmp = prevState.finalDetails.selectedEmp.filter(e => e.empId !== prevState.basicDetails.pManager)
                    selectedEmp.push(newEmp)
                    return {
                        finalDetails: { ...this.state.finalDetails, selectedEmp: selectedEmp }
                    }
                } else {
                    return {
                        finalDetails: { ...this.state.finalDetails, selectedEmp: [...this.state.finalDetails.selectedEmp, newEmp] }
                    }
                }
            });
        }
        else {
            if (e.target.id === "pEmp") {
                alert("Already Exist")
            }
        }

    }

    handleMultipleLoc(e) {
        let oldState = this.state.dateLocation;
        let chosenLocId = e.target.value;
        let chosenLoc = this.props.locList.find(({ id }) => id === +chosenLocId)
        let LocExist = this.state.dateLocation.pLoc.find(({ locId }) => locId === +chosenLocId)
        // console.log(chosenLocId,LocExist,chosenLoc)
        e.target.value = ""
        if (!LocExist) {
            const newLoc = { locId: chosenLoc.id, location_addr1: chosenLoc.location_addr1, location_addr2: chosenLoc.location_addr2, location_city: chosenLoc.location_city, location_state: chosenLoc.location_state, location_zip: chosenLoc.location_zip, location_lat: chosenLoc.location_lat, location_lon: chosenLoc.location_lon, location_type: chosenLoc.location_type, location_status: chosenLoc.location_status, location_county: chosenLoc.location_county };
            oldState.pLoc.push(newLoc)
            this.setState({ dateLocation: oldState });

            let mapPushPins = oldState.pLoc.map((locObj, indx) => {
                return {
                    "location": [+locObj.location_lat, +locObj.location_lon],
                    "addHandler": "mouseover",
                    "infoboxOption": { title: locObj.location_city, description: locObj.location_addr1 + ',' + locObj.location_addr2 },
                    "pushPinOption": { title: 'Location ' + ++indx }
                }
            })
            this.setState({ mapPushPins })
        }
        else {
            alert("Already Exist")
        }
    }

    onLocDelete(id) {
        const pLoc = this.state.dateLocation.pLoc.filter(loc => loc.locId !== id);
        const mapPushPins = pLoc.map((locObj, indx) => {
            return {
                "location": [+locObj.location_lat, +locObj.location_lon],
                "addHandler": "mouseover",
                "infoboxOption": { title: locObj.location_city, description: locObj.location_addr1 + ',' + locObj.location_addr2 },
                "pushPinOption": { title: 'Location ' + ++indx, option: { color: 'yellow' } },
            }
        })
        this.setState({ mapPushPins })
        this.setState({ dateLocation: { ...this.state.dateLocation, pLoc: pLoc } })
    }

    handleChangeEmp(id, evt) {
        let prevState = { ...this.state.finalDetails }
        let empDets = this.state.finalDetails.selectedEmp.find((employee) => (employee.empId === id))
        // console.log(id,evt)
        let selectedEmp = prevState.selectedEmp.map(emp => {
            if (emp.empId === id) {
                if (evt.target.id === 'pEmpAssFromDate') {
                    if (moment(evt.target.value).isBetween(moment(this.state.dateLocation.pstrtDate).subtract(1, 'd'), moment(this.state.dateLocation.pEndDate).add(1, 'd'))) {
                        emp[evt.target.id] = evt.target.value
                        this.setState({ errorMsg: '', showAlert: false })
                    } else {
                        emp[evt.target.id] = ''
                        //  moment().format('YYYY-MM-DDTHH:mm:ss')
                        this.setState({ errorMsg: `Please Enter Valid Date Between ${moment(this.state.dateLocation.pstrtDate).format('MMM DD YYYY')} - ${moment(this.state.dateLocation.pEndDate).format('MMM DD YYYY')}`, showAlert: true })
                    }
                } else if (evt.target.id === 'pEmpAssToDate') {
                    if (!empDets.pEmpAssFromDate) {
                        emp[evt.target.id] =  ''
                        // moment().format('YYYY-MM-DDTHH:mm:ss')
                        this.setState({ errorMsg: `Please Enter Associate From Field First`, showAlert: true })
                    } else if (empDets.pEmpAssFromDate && !moment(evt.target.value).isBetween(moment(empDets.pEmpAssFromDate).subtract(1, 'd'), moment(this.state.dateLocation.pEndDate).add(1, 'd'))) {
                        emp[evt.target.id] =  ''
                        // moment().format('YYYY-MM-DDTHH:mm:ss')
                        this.setState({ errorMsg: `Please Enter Associate To Field in range ${moment(empDets.pEmpAssFromDate).format('MMM DD YYYY')} - ${moment(this.state.dateLocation.pEndDate).format('MMM DD YYYY')}`, showAlert: true })
                    } else {
                        emp[evt.target.id] = evt.target.value
                        this.setState({ errorMsg: '', showAlert: false })
                    }
                } else {
                    emp[evt.target.id] = evt.target.value
                }
            }
            return emp
        })
        this.setState({ finalDetails: { ...this.state.finalDetails, selectedEmp: selectedEmp } })
    }

    onDelete(id) {
        const selectedEmp = this.state.finalDetails.selectedEmp.filter(e => e.empId !== id);
        this.setState({ finalDetails: { ...this.state.finalDetails, selectedEmp: selectedEmp } })
    }

    componentDidMount() {
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": '*',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
             Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
          }
          
        if (this.props.edit) {
            this.props.project_details.pLoc.forEach(loc => {
                this.handleMultipleLoc({ target: { value: loc.id } })
            })
        }
    }

    render() {
        return (
            <div style={{ textAlign: "left", marginLeft: "10px" }}>
                {(this.state.showModal) ? (<MsgModalBox edit={this.props.edit} addNew={this.addNew} goHome={this.goHome} text="Project" showError={this.state.addError} style={{ position: "absolute" }} taskDates={[]}/>) : (
                    <Form onSubmit={(e) => {if (this.onChange('finalDetails')){ this.addProjectToDB(e)}
                    else { e.preventDefault()}
                    }} >
                        <h4>{this.props.edit ? "Edit Project" : this.props.customerPage ? "" : "New Project"} </h4>
                        {this.state.showAlert &&
                            <Alert variant="info">
                                {!this.state.errorMsg ? 'Please Fill out missing form fields' : this.state.errorMsg}
                            </Alert>}
                        <Tabs activeKey={this.state.key} id="project-tabs" onSelect={key => this.onChange(key)}>
                            <Tab eventKey="basicDetails" title={(this.state.basicDetails_val) ?
                                (<div className="d-flex flex-column"><i style={{ fontSize: "0.7rem" }} className="fas fa-check text-center"></i><div style={{ margin: "-2px", fontSize: "0.9rem" }}>Basic Details</div></div>) :
                                (<div style={{ margin: "-1px" }}>Basic Details</div>)}>
                                <BasicDets
                                    edit={this.props.edit}
                                    handlebasicDetailsChange={this.handlebasicDetailsChange}
                                    formVals={this.state.basicDetails}
                                    customerList={this.props.customerList}
                                    empList={this.props.empList}
                                    proj_employees={this.props.proj_Employees}
                                    contactList={this.props.contactList}
                                    onContinue={this.onChange}
                                />
                            </Tab>
                            <Tab eventKey="dateLocation" title={(this.state.dateLocation_val) ?
                                (<div className="d-flex flex-column"><i style={{ fontSize: "0.7rem" }} className="fas fa-check text-center"></i><div style={{ margin: "-2px", fontSize: "0.9rem" }}>Date & Location</div></div>) :
                                (<div style={{ margin: "-1px" }}>Date & Location</div>)}>
                                <DateLocation
                                    edit={this.props.edit}
                                    locList={this.props.locList}
                                    token={this.props.token}
                                    // cusLoc={(!this.state.basicDetails.pCustomer)?[]:this.props.customerList.find((custObj)=>{return custObj.id === +this.state.basicDetails.pCustomer}).locations}
                                    formVals={this.state.dateLocation}
                                    mapRender={this.props.mapRender}
                                    currency={this.props.currency}
                                    handledateLocationChange={this.handledateLocationChange}
                                    handleMultipleLoc={this.handleMultipleLoc}
                                    onLocDelete={this.onLocDelete}
                                    pLocations={this.state.dateLocation.pLoc}
                                    pushPins={this.managePushPins}
                                    mapPushPins={this.state.mapPushPins}
                                    onContinue={this.onChange}
                                    error={this.state.errorMsg}
                                    addProjectToDB={this.addProjectToDB}
                                    taskList={this.props.edit ? this.props.taskList : null}
                                />
                            </Tab>
                            {!this.props.edit ?
                                <Tab eventKey="finalDetails" title={(this.state.finalDetails_val) ?
                                    (<div className="d-flex flex-column"><i style={{ fontSize: "0.7rem" }} className="fas fa-check text-center"></i><div style={{ margin: "-2px", fontSize: "0.9rem" }}>Final Details</div></div>) :
                                    (<div style={{ margin: "-1px" }}>Final Details</div>)}>
                                    <FinalDets
                                        handleSelectedEmp={this.handleSelectedEmp}
                                        handleChangeEmp={this.handleChangeEmp}
                                        onDelete={this.onDelete}
                                        formVals={this.state.finalDetails}
                                        empStatus={this.props.empStatus}
                                        currency={this.props.currency}
                                        // frequency={dd.generic.frequency.map((fre)=> {return fre.value})}
                                        frequency={this.props.frequency.map((fre)=> {return fre.feature_value})}
                                        
                                        //onContinue={this.onChange()}
                                        // validationCheck1={this.state.basicDetails_val}
                                        // validationCheck2={this.state.dateLocation_val}
                                        // validationCheck3={this.state.finalDetails_val}
                                        empList={this.props.empList}
                                        pManagerId={this.state.basicDetails.pManager}
                                        selectedEmp={this.state.finalDetails.selectedEmp}
                                        addProjectToDB={this.addProjectToDB}
                                        isSubmitting={this.state.isSubmitting}
                                    />
                                </Tab>
                                : null}
                        </Tabs>
                    </Form>)}
                {this.state.goHome &&
                    <Redirect to="/" />
                }
            </div>
        );
    };
}

export default ProjectForm;