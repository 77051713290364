import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import moment from "moment-timezone";
import {
  TextField,
  Typography,
  Button,
  Box,
  darken,
  lighten,
  responsiveFontSizes,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  DataGridPro,
  gridColumnsTotalWidthSelector,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import SaveViewButton from "../../DataGrid/SaveViewButton";
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Tooltip from "@mui/material/Tooltip";
import ClaimCSV from "../../ClaimCSV/ClaimCSV";
import "./Claims.css";
import { Modal } from "react-bootstrap";
import makeStyles from "@mui/styles/makeStyles";
import Upload from "../../Billing/Claims/Upload";
import { ContentCopy, ErrorRounded, ErrorSharp } from "@mui/icons-material";
import { ctxDispatchToMultiEnumProps } from "@jsonforms/react";
import { update } from "@jsonforms/core";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../../config';
import { Button as RBButton } from "react-bootstrap";
import withStyles from "@mui/styles/withStyles";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#3FA7D6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3FA7D6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "lightblue",
      },
      "&:hover fieldset": {
        borderColor: "#3FA7D6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3FA7D6",
      },
    },
  },
})(TextField);

const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })


const useStyles = makeStyles((theme) => ({
  button: {
    color: "#fff",
    backgroundColor: "#3FA7D6",
    "& .MuiButton-label": {
      color: "#fff", // or black
    },
  },
}));

const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

const dateProcCodeModifComparator = (val1, val2) => {
  if (val1.status !== 'Ready to Bill' && val2.status !== 'Ready to Bill') {
    return moment(val1.createDate).diff(moment(val2.createDate));
  }
  // return moment(val1.createDate).diff(moment(val2.createDate));
  let mods1 = val1.procCodeModifer;
  if (typeof mods1 !== "string" || mods1 === "null") {
    mods1 = "";
  }
  mods1 = mods1.split(":");
  let mods2 = val2.procCodeModifer;
  if (typeof mods2 !== "string" || mods2 === "null") {
    mods2 = "";
  }
  mods2 = mods2.split(":");
  // console.log(val1.procCode,val2.procCode)
  let t1 = val1.isSubmittable && val1.status === "Ready to Bill" && !((mods1[0] === "" && mods1.length === 1 && val1.procCode.length === 5) || (mods1[0] !== "" && mods1.filter((m) => m.length === 2).length === mods1.length && val1.procCode.length === 5)) ? moment('12-31-2100', 'MM-DD-YYYY') : moment(val1.createDate);
  let t2 = val2.isSubmittable && val2.status === "Ready to Bill" && !((mods2[0] === "" && mods2.length === 1 && val2.procCode.length === 5) || (mods2[0] !== "" && mods2.filter((m) => m.length === 2).length === mods2.length && val2.procCode.length === 5)) ? moment('12-31-2100', 'MM-DD-YYYY') : moment(val2.createDate);
  return t1.diff(t2);
}

const checkClaim = (field, newVal, payerList) => {
  let mandatoryFields = [
    "DDDID",
    "FirstName",
    "LastName",
    "Gender",
    "DateOfBirth",
    "memberMedicaid",
    "priorAuth",
    "procedureCode",
    "startDate",
    "endDate",
    "DiagnosisCode",
    "authUnits",
    "authAmount",
  ];
  let numFields = ["DDDID", "memberMedicaid", "priorAuth", "authUnits"];
  let decFields = ["authAmount"];
  let dateFields = ["startDate", "endDate", "DateOfBirth"];
  let genderFields = ["Gender"];
  let fixedLengthFields = ["priorAuth"];
  let nameFields = ["FirstName", "LastName"];
  let alphanumericFields = ["procedureCode", "procedureModifierCode"];

  let lengthMap = {
    "priorAuth": 10,
  }

  let empty = false,
    notNum = false,
    notDec = false,
    invalidGender = false,
    invalidDate = false,
    invalidLength = false,
    invalidName = false,
    invalidAlphanum = false,
    earlyBillDate = false,
    invalidPayerId = false,
    invalidDiagnosisCode = false;

  if (mandatoryFields.includes(field)) {
    newVal ? (empty = false) : (empty = true);
  }
  if (numFields.includes(field)) {
    if (
      !(
        typeof newVal === "number" ||
        (typeof newVal === "string" &&
          /^\d+$/.test(newVal) &&
          Number.isInteger(Number(newVal)) &&
          Number(newVal) > 0)
      )
    ) {
      notNum = true;
    }
  }
  if (decFields.includes(field)) {
    if (
      !(
        typeof newVal === "number" ||
        (typeof newVal === "string" &&
          !isNaN(Number(newVal)) &&
          Number(newVal) > 0)
      )
    ) {
      notDec = true;
    }
  }
  if (fixedLengthFields.includes(field)) {
    if (newVal.length !== lengthMap[field]) {
      invalidLength = true;
    }
  }
  if (dateFields.includes(field)) {
    if (newVal !== undefined && !moment(moment(newVal).format("MM/DD/YYYY"), "MM/DD/YYYY", true).isValid()) {
      invalidDate = true;
    }
    if (!invalidDate && field === 'endDate' && moment().isBefore(moment(newVal))) {
      earlyBillDate = true;
    }

  }
  if (genderFields.includes(field)) {
    if (newVal !== "M" && newVal !== "F") {
      invalidGender = true;
    }
  }
  if (nameFields.includes(field)) {
    if (! /^[a-z ,.'-]+$/i.test(String(newVal))) {
      invalidName = true;
    }
  }
  if (alphanumericFields.includes(field)) {
    if (! /^[a-z0-9/:]+$/i.test(String(newVal).replaceAll(".", "").toUpperCase()) && mandatoryFields.includes(field)) {
      invalidAlphanum = true;
    }
  }
  if (field === "DiagnosisCode") {
    if (! /^[a-z0-9/]+$/i.test(String(newVal).replaceAll(".", "").toUpperCase())) {
      invalidDiagnosisCode = true;
    }
  }
  if (field === "payerId") {
    if (!payerList.map(p => String(p.payerId)).includes(newVal)) {
      invalidPayerId = true;
    }
  }
  return {
    empty,
    notNum,
    notDec,
    invalidDate,
    invalidGender,
    invalidLength,
    invalidName,
    invalidAlphanum,
    invalidDiagnosisCode,
    earlyBillDate,
    invalidPayerId
  };
};

const useCheckClaimField = () => {
  return useCallback(
    (params, payerList) =>
      new Promise((resolve, reject) =>
        setTimeout(() => {
          // console.log(params)
          let errors = checkClaim(params.field, params.value, payerList);
          let errorPresent = Object.values(errors).filter((p) => p === true).length !== 0;
          if (errorPresent) {
            if (errors.empty) {
              reject(
                new Error("Error while saving user. This field can't be empty.")
              );
            } else if (errors.notNum) {
              reject(
                new Error(
                  "Error while saving user. This field has to be a number."
                )
              );
            } else if (errors.invalidDate) {
              reject(
                new Error(
                  "Error while saving user. This field has to be a valid date in MM/DD/YYYY format."
                )
              );
            } else if (errors.invalidGender) {
              reject(
                new Error(
                  "Error while saving user. Please select one from M/F."
                )
              );
            } else if (errors.notDec) {
              reject(
                new Error(
                  "Error while saving user. This field has to be a decimal."
                )
              );
            } else if (errors.invalidLength) {
              if (params.field === "priorAuth") {
                reject(
                  new Error(
                    "Error while saving user. This field has to be of length 10 and should start from 01 through 15."
                  )
                );
              }
            } else if (errors.invalidPayerId) {
              reject(
                new Error(
                  "Error while saving user. This field has to be one of the payer ids added to your agency."
                )
              );
            }
          } else {
            resolve({ id: params.id, [params.field]: params.value });
          }
        }, 200)
      ),
    []
  );
};

export default function Claims(props) {
  const [gridId, setGridId] = useState(48);
  const apiRef = useGridApiRef();
  let currClaimsList = useRef([]);

  const [payerList, setPayerList] = useState([]);
  const [claimsList, setClaimsList] = useState([]);
  const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));
  const [currentMonthEnd, setCurrMonthEnd] = useState(moment().endOf("month"));
  const [isLoading, setLoading] = useState(true);
  const [isLoadingApiCall, setLoadingApiCall] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [noteText, setNoteText] = useState('');
  const [bulkEditModal, setBulkEditModal] = useState(false);
  const [bulkEditMode, setBulkEditMode] = useState("");
  const [bulkEdittedVal, setBulkEdittedVal] = useState("");
  const [nextStepStatus, setNextStep] = useState(false);
  const [delClaimsStatus, setDelClaim] = useState(false);
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [csvAdd, setCsvAdd] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectValidClaims, setSelectValidClaims] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState(null);
  const [loggedToSegment, setLoggedToSegment] = useState(false);
  const [editStatusMode, setEditStatusMode] = useState(false);
  const performChecks = useCheckClaimField();

  const handleCloseSnackbar = () => setSnackbar(null);

  const logToSegment = () => {
    if (loggedToSegment) {
      return;
    }
    let page = String(window.location.href).split("/").pop()
    console.log(page, window.location.href)
    if (page.includes("fieldworker") || page === "" || page === "#") {
      page = "Home";
    } else {
      page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
    }
    console.log(page)
    analytics.page(page + " : Claims", {
      title: page + " : Claims",
      path: page + " : Claims",
    }, {
      userId: String(props.user.emp_id),
    })
    console.log("logged to segment successfully")
    setLoggedToSegment(true);
  }

  const handleCellEditCommit = useCallback(
    async (updatedRow, originalRow) => {
      try {
        let p = undefined;
        for (let [key, value] of Object.entries(updatedRow)) {
          if (updatedRow[key] !== originalRow[key]) {
            p = {
              "field": key,
              "value": value,
              "id": updatedRow["id"],
            }
          }
        }
        if (p === undefined) {
          setSnackbar({
            children: "Claim successfully saved.",
            severity: "success",
          });
          return updatedRow;
        }
        setEditStatusMode(false);
        console.log(p);
        console.log(selectionModel);
        if (p.field === 'notes') {
          return;
        }
        let params;
        if (typeof (p.value) === "string" && p.value.includes(",")) {
          throw { message: "Please use '/' as a seperator instead of a ','." };
        }
        if (["FirstName", "LastName"].includes(p.field) && ! /^[a-z ,.'-]+$/i.test(String(p.value))) {
          throw { message: "Names can only contain letters, commas, periods, hyphens and single quotation marks." };
        }
        if (String(p.value).trim() !== '' && (["procedureCode", "procedureModifierCode"].includes(p.field) && ! /^[a-z0-9/:]+$/i.test(String(p.value).replaceAll(".", "").toUpperCase()))) {
          throw { message: "This field only supports alphanumeric characters, backslashes and colons without spaces." };
        }
        if (["DiagnosisCode"].includes(p.field) && ! /^[a-z0-9/]+$/i.test(String(p.value).replaceAll(".", "").toUpperCase())) {
          throw { message: "This field only supports alphanumeric characters and backslashes without spaces." }
        }
        if (["Gender", "procedureCode", "procedureModifierCode", "DiagnosisCode"].includes(p.field)) {
          params = { ...p, value: String(p.value).replaceAll(".", "").toUpperCase() };
        } else {
          params = { ...p };
        }
        updatedRow[params.field] = params.value;
        if (["Gender", "procedureCode", "procedureModifierCode", "DiagnosisCode"].includes(p.field) && currClaimsList.current.filter((claim) => claim.id === params.id)[0][params.field] === params.value && currClaimsList.current.filter((claim) => claim.id === params.id)[0][params.field] !== p.value) {
          throw { message: "No lower case letters allowed for this field." };
        }
        if (currClaimsList.current.filter((claim) => claim.id === params.id)[0][params.field] !== params.value) {
          const response = await performChecks(params, payerList);
          if (response.id !== undefined) {
            currClaimsList.current.map((claim) => {
              if (claim.id === params.id) {
                claim[params.field] = params.value;
              }
            });
            currClaimsList.current.map((claim) => {
              if (claim.id === params.id) {
                errorChecker(claim.id, payerList);
              }
            });
          }
          if (currClaimsList.current.filter((claim) => claim.id === params.id)[0]["claim_ID"] !== undefined) {
            await updateClaimData(
              [currClaimsList.current.filter((claim) => claim.id === params.id)[0]
                .claim_ID],
              params.field,
              params.value === null ? "" : params.value
            );
          } else {
            errorChecker(params.id, payerList);
            // console.log(currClaimsList.current[params.id].isSubmittable);
            if (currClaimsList.current[params.id].isSubmittable) {
              await insertClaimData(params.id);
            }
          }
          if (currClaimsList.current.filter((claim) => claim.id === params.id)[0]["Status"] === 'Denied' || currClaimsList.current.filter((claim) => claim.id === params.id)[0]["Status"] === 'Pending') {
            await updateClaimData(
              [currClaimsList.current.filter((claim) => claim.id === params.id)[0]
                .claim_ID],
              "Status",
              "Ready to Bill"
            );
            currClaimsList.current.map((claim) => {
              if (claim.id === params.id) {
                claim["Status"] = "Ready to Bill";
                errorChecker(claim.id, payerList);
              }
            });
          }
          setClaimsList(currClaimsList.current);
          setUpdateData(!updateData);
          setSnackbar({
            children: "Claim successfully saved.",
            severity: "success",
          });
          return updatedRow;
        }
      } catch (error) {
        setSnackbar({ children: error.message, severity: "error" });
        return originalRow;
        // Restore the row in case of error
      }
    },
    [performChecks, claimsList, payerList]
  );

  const handleAddRow = () => {
    currClaimsList.current = [
      ...currClaimsList.current,
      {
        id: currClaimsList.current.length,
        DDDID: "",
        FirstName: "",
        LastName: "",
        DateOfBirth: "",
        Gender: "",
        patientAddrLine1: "",
        patientCity: "",
        patientState: "",
        patientZip: "",
        placeOfServiceCode: "",
        patientRel: "",
        payerId: "",
        memberMedicaid: "",
        priorAuth: "",
        procedureCode: "",
        procedureModifierCode: "",
        startDate: "",
        endDate: "",
        DiagnosisCode: "",
        authUnits: "",
        authAmount: "",
        totalCharges: "",
        errors: "",
        Status: "Ready to Bill",
        Response: "",
        CreateDate: moment().tz('America/New_York').toISOString(),
        LastResponseDate: "",
        errCode: "",
        errDescription: "",
        isSubmittable: false,
      }];
    setClaimsList(currClaimsList.current);
  }

  // const handleEditRowsModelChange = useCallback(
  //   (model) => {
  //     async function updateClaimData(id, colName, newVal, ind) {
  //       let claims = await axios.put(APIURL + "/claims", {
  //         claim_ID: id,
  //         colName: colName,
  //         newVal: newVal,
  //       });
  //       //put checks
  //       let currClaimsList = claimsList;
  //       console.log(newVal, currClaimsList[ind][colName]);
  //       currClaimsList[ind][colName] = newVal;
  //       setClaimsList(currClaimsList);
  //       setUpdateData(!updateData);
  //     }
  //     const editedIds = Object.keys(model);

  //     let colName = "";
  //     let newVal = "";
  //     let ind = -1;
  //     if (editedIds.length === 0) {
  //       console.log(editRowData);
  //       for (const key in editRowData) {
  //         if (
  //           (!key.includes("Date") &&
  //             editRowData[key].value != selectedClaims[key]) ||
  //           (key.includes("Date") &&
  //             editRowData[key].value !=
  //               moment(selectedClaims[key]).utc().format("L"))
  //         ) {
  //           console.log(key);
  //           colName = `${key}`;
  //           newVal = `${editRowData[key].value}`;
  //           ind = selectedClaims.id;
  //           break;
  //         }
  //       }
  //       if (colName) {
  //         const errors = checkClaim(colName, newVal);
  //         if (!(errors.empty || errors.notDec || errors.notNum)) {
  //           updateClaimData(selectedClaims.claim_ID, colName, newVal, ind);
  //         }
  //       }
  //     } else {
  //       setEditRowData(model[editedIds[0]]);
  //     }
  //     setEditRowsModel(model);
  //   },
  //   [editRowData]
  // );

  async function updateClaimData(ids, colName, newVal) {
    if (colName === 'billingNote') {
      return;
    }
    let claims = await axios.put(APIURL + "/claims", {
      claim_IDs: ids,
      colName: colName,
      newVal: newVal,
    });
  }

  async function insertClaimData(id) {
    let obj = currClaimsList.current[id];
    obj["companyID"] = props.user.comp_id;
    obj["uploadedBy"] = props.user.emp_id;
    let resp = await axios.post(APIURL + "/claims", {
      claimInfo: obj,
    })
    console.log(obj)
    currClaimsList.current.forEach((claim) => {
      if (claim.id === id) {
        claim.claim_ID = resp.data.claim_ID;
        claim.companyID = props.user.comp_id;
        claim.uploadedBy = String(props.user.emp_id);
      }
    })
    setClaimsList(currClaimsList);
    console.log(currClaimsList.current)

  }

  function errorChecker(id, payerList) {
    let fieldToHeader = {
      DDDID: "DDDID",
      FirstName: "Patient First Name",
      LastName: "Patient Last Name",
      DateOfBirth: "Patient DOB",
      Gender: "Gender",
      memberMedicaid: "Medicaid ID",
      priorAuth: "Prior Authorization",
      procedureCode: "Procedure Code",
      startDate: "Task Start Date",
      endDate: "Task End Date",
      DiagnosisCode: "Diagnosis Code",
      authUnits: "Authorized Units",
      authAmount: "Authorized Amount",
      payerId: "Payer Id",
    };
    let allErrors = [];
    // console.log(currClaimsList.current[id])
    for (const [key, val] of Object.entries(currClaimsList.current[id])) {
      if (key in fieldToHeader) {
        var errors = checkClaim(key, val, payerList);
        if (errors.empty) {
          allErrors.push(`${fieldToHeader[key]} can't be empty.`);
        } else if (errors.notNum) {
          allErrors.push(`${fieldToHeader[key]} has to be a number.`);
        } else if (errors.notDec) {
          allErrors.push(`${fieldToHeader[key]} has to be a decimal.`);
        } else if (errors.invalidDate) {
          allErrors.push(`${fieldToHeader[key]} has to be a valid date(MM/DD/YYYY).`);
        } else if (errors.invalidGender) {
          allErrors.push(`${fieldToHeader[key]} has to be either M or F.`);
        } else if (errors.invalidLength) {
          if (key === "priorAuth") {
            allErrors.push(`${fieldToHeader[key]} has to be of length 10 and has to start from 01 through 15.`);
          }
        } else if (errors.invalidName) {
          allErrors.push(`${fieldToHeader[key]} can only contain letters, commas, periods, hyphens and single quotation marks.`);
        } else if (errors.invalidAlphanum) {
          allErrors.push(`${fieldToHeader[key]} only supports alphanumeric characters, backslashes and colons without spaces.`);
        } else if (errors.invalidDiagnosisCode) {
          allErrors.push(`${fieldToHeader[key]} only supports alphanumeric characters and backslashes without spaces.`);
        } else if (errors.earlyBillDate) {
          allErrors.push(`${fieldToHeader[key]} should be same or before today's date.`);
        } else if (errors.invalidPayerId) {
          allErrors.push(`${fieldToHeader[key]} has not been added to your company, add it to your company from your company settings first.`)
        }
      }
    }
    currClaimsList.current[id].errors = allErrors;
    currClaimsList.current[id].isSubmittable =
      allErrors.length === 0 &&
      (["Ready to Bill"].includes(currClaimsList.current[id].Status) && !["Deferred"].includes(currClaimsList.current[id].Status));
  }

  useEffect(() => {
    async function fetchData() {
      axios.defaults.headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
        "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
        Authorization:
          "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
      };
      console.log(axios.defaults.headers)
      let payerDetails = await axios.get(APIURL + `/associate_payer`, {
        params : {
          compId: props.comp_id,
        }
      });
      console.log(payerDetails.data);
      setPayerList(payerDetails.data);
      let claims = await axios.get(APIURL + "/claims", {
        params: { companyID: props.comp_id },
      });
      let allClaims = claims.data;
      currClaimsList.current = allClaims;
      let id_num = 0;
      currClaimsList.current.map((claim) => {
        errorChecker(id_num, payerDetails.data);
        claim.id = id_num++;
      });
      setClaimsList(currClaimsList.current);
      setLoading(false);
    }
    logToSegment();
    fetchData();
  }, []);

  useEffect(() => {
    async function sendToBilling(data, newList) {
      setLoadingApiCall(true);
      let billingStatus = await axios
        .post(APIURL + "/medicaid/claimmd", {
          entries: data,
          comp_id: props.user.comp_id,
          emp_id: props.user.emp_id,
          empName: props.user.emp_name,
          companyName: props.user.company_name,
        })
        .then(
          async (response) => {
            console.log(response);
            if (!response.data.njResp) {
              currClaimsList.current = newList;
              let ids = claimsList
                .filter((claim) => selectionModel.includes(claim.id))
                .map((claim) => claim.claim_ID);
              updateClaimData(ids, "Status", "Released");
              currClaimsList.current = claimsList;
              for (var i = 0; i < selectionModel.length; i++) {
                currClaimsList.current[selectionModel[i]]["Status"] =
                  "Released";
                currClaimsList.current[selectionModel[i]][
                  "isSubmittable"
                ] = false;
              }
              logClaimGeneration(
                true,
                String(data.length) + " claims submitted"
              );
              setLoadingApiCall(false);
              setClaimsList(currClaimsList.current);
              setUpdateData(!updateData);
              setSelectValidClaims(false);
              setNextStep(false);
              // console.log(currClaimsList.current.filter((claim) => claim.isSubmittable === true).length)
              setSelectionModel([]);
              setSnackbar({
                children: "Claims sent!",
                severity: "success",
              });
              return;
            }
            let upres = response.data.njResp;
            const objX12 = {
              comp_id: props.user.comp_id,
              emp_name: props.user.emp_name,
              fileName: String(upres.id),
              fileLocation: `${upres.url}`,
              Status: "READY",
              SubmitDate: moment().toISOString(),
            };
            let billingX12Status = await axios
              .post(APIURL + "/generateX12", objX12)
              .then(
                async (resp) => {
                  console.log(resp);
                  currClaimsList.current = newList;
                  let ids = claimsList
                    .filter((claim) => selectionModel.includes(claim.id))
                    .map((claim) => claim.claim_ID);
                  updateClaimData(ids, "Status", "Released");
                  currClaimsList.current = claimsList;
                  for (var i = 0; i < selectionModel.length; i++) {
                    currClaimsList.current[selectionModel[i]]["Status"] =
                      "Released";
                    currClaimsList.current[selectionModel[i]][
                      "isSubmittable"
                    ] = false;
                  }
                  logClaimGeneration(
                    true,
                    String(data.length) + " claims submitted"
                  );
                  setLoadingApiCall(false);
                  setClaimsList(currClaimsList.current);
                  setUpdateData(!updateData);
                  setSelectValidClaims(false);
                  setNextStep(false);
                  // console.log(currClaimsList.current.filter((claim) => claim.isSubmittable === true).length)
                  setSelectionModel([]);
                  setSnackbar({
                    children: "The file has been generated!",
                    severity: "success",
                  });
                },
                (error) => {
                  console.log(error);
                  logClaimGeneration(
                    false,
                    String(data.length) + " claims submitted"
                  );
                  setLoadingApiCall(false);
                  setClaimsList(currClaimsList);
                  setUpdateData(!updateData);
                  setSelectValidClaims(false);
                  setNextStep(false);
                  setSelectionModel([]);
                  setSnackbar({
                    children: "Something went wrong. Try again!",
                    severity: "error",
                  });
                }
              );
          },
          (error) => {
            console.log(error);
            logClaimGeneration(
              false,
              String(data.length) + " claims submitted"
            );
            setLoadingApiCall(false);
            setClaimsList(currClaimsList);
            setUpdateData(!updateData);
            setSelectValidClaims(false);
            setNextStep(false);
            setSelectionModel([]);
            setSnackbar({
              children: "Something went wrong. Try again!",
              severity: "error",
            });
          }
        );
    }

    async function logClaimGeneration(fileGenerationStatus, error) {
      let sessionID = window.localStorage.getItem("Fw4_access_Token");
      var sha1 = require("sha1");
      var hash = sha1(sessionID);
      var log_data = {
        email: props.user.email,
        actionType: fileGenerationStatus
          ? "X12 Generated"
          : "X12 Generation Failed",
        actionSubType: error,
        sessionID: hash,
      };
      let msg = await axios.post(APIURL + "/activity_log", log_data);
    }

    if (nextStepStatus) {
      if (selectionModel.length != 0) {
        let dataToBeSent = claimsList.filter((claim) =>
          selectionModel.includes(claim.id)
        );
        sendToBilling(dataToBeSent, claimsList);
      }
    }
  }, [nextStepStatus]);

  useEffect(() => {

    async function delClaims(validData, invalidData) {
      setLoadingApiCall(true);
      let invalidIds = invalidData.map((claim) => claim.id);
      currClaimsList.current = currClaimsList.current.filter((claim) => !invalidIds.includes(claim.id));
      currClaimsList.current.forEach((claim, index) => {
        claim.id = index;
      })
      let validIds = validData.filter((claim) => claim.claim_ID !== undefined).map((claim) => claim.claim_ID);
      if (validIds.length !== 0) {
        let resp = await axios.delete(APIURL + "/claims", {
          data: {
            idsToBeDeleted: validIds,
          }
        });
        console.log(resp);
        currClaimsList.current = currClaimsList.current.filter((claim) => !validIds.includes(claim.claim_ID))
      }
      // console.log(currClaimsList.current.map((claim) => claim.id))
      currClaimsList.current.forEach((claim, index) => {
        claim.id = index;
      })
      // console.log(currClaimsList.current.map((claim) => claim.id))
      setClaimsList(currClaimsList.current);
      setSelectionModel([]);
      setSnackbar({
        children: "Claims successfully deleted!",
        severity: "success",
      })
      setLoadingApiCall(false);
      setDelClaim(!delClaimsStatus);
    }

    if (delClaimsStatus) {
      if (selectionModel.length !== 0) {
        let dataToBeDeleted = selectionModel.map((id) => {
          return currClaimsList.current[id];
        })
        console.log(dataToBeDeleted)
        let validClaims = dataToBeDeleted.filter((claim) => claim.claim_ID !== undefined)
        let invalidClaims = dataToBeDeleted.filter((claim) => claim.claim_ID === undefined)
        console.log(validClaims, invalidClaims)
        delClaims(validClaims, invalidClaims);
      }
    }
  }, [delClaimsStatus])

  useEffect(() => {
    async function fetchData() {
      let claims = await axios.get(APIURL + "/claims", {
        params: { companyID: props.comp_id, emp_id: props.user.emp_id },
      });
      let payerDetails = await axios.get(APIURL + `/associate_payer`, {
        params : {
          compId: props.comp_id,
        }
      });
      console.log(payerDetails.data);
      setPayerList(payerDetails.data);
      let allClaims = claims.data;
      currClaimsList.current = allClaims;
      let id_num = 0;
      currClaimsList.current.map((claim) => {
        errorChecker(id_num,payerDetails.data);
        claim.id = id_num++;
      });
      setClaimsList(currClaimsList.current);
      setLoading(false);
    }
    fetchData();
  }, [csvAdd]);

  useEffect(() => {
    if (selectValidClaims) {
      let readyToSubmitRows = claimsList
        .filter((r) => r.isSubmittable && moment(r.CreateDate).month() === currentMonth.month() && moment(r.CreateDate).year() === currentMonth.year())
        .map((r) => r.id);
      setSelectionModel(readyToSubmitRows);
    } else {
      setSelectionModel([]);
    }
  }, [selectValidClaims]);

  useEffect(() => {
    getClaimsList();
  }, [updateData]);

  async function updateBillingNote(id, note, cid) {
    try {
      if (note && note.replace(/\s/g, '').length !== 0) {
        await axios.put(APIURL + "/accountsReceivable/billingNote", {
          billing_note_id: id,
          billing_note: note,
          empID: props.user.emp_id,
        });
        currClaimsList.current = currClaimsList.current.map((claim) => {
          if (claim.claim_ID === cid) {
            claim.billing_note = note;
          }
          return claim;
        })
        console.log(currClaimsList.current);
        setClaimsList(currClaimsList);
        setSnackbar({
          children: "Note successfully saved.",
          severity: "success",
        });
      } else {
        setSnackbar({
          children: "Note cannot be empty",
          severity: "error"
        });
      }
    } catch (error) {
      setSnackbar({
        children: error.message,
        severity: "error"
      });
    }
    setEditNote(false);
    setEditRow(null);
  }

  async function bulkUpdateSelected() {
    try {
      if (bulkEditMode === 'authAmount') {
        let val = parseFloat(bulkEdittedVal);
        if (isNaN(val)) {
          setSnackbar({
            children: "Amount should be a valid decimal, please try again.",
            severity: "error"
          });
        } else {
          await updateClaimData(currClaimsList.current.filter((claim, index) => selectionModel.includes(index)).map((claim) => claim.claim_ID), bulkEditMode, bulkEdittedVal);
          currClaimsList.current = currClaimsList.current.map((claim, ind) => {
            if (selectionModel.includes(ind)) {
              claim[bulkEditMode] = bulkEdittedVal;
            }
            return claim;
          })
          setClaimsList(currClaimsList);
          setSnackbar({
            children: "Bulk edit success.",
            severity: "success",
          });
        }
      } else if (bulkEditMode === 'Status') {
        await updateClaimData(currClaimsList.current.filter((claim, index) => selectionModel.includes(index)).map((claim) => claim.claim_ID), bulkEditMode, bulkEdittedVal);
        currClaimsList.current = currClaimsList.current.map((claim, ind) => {
          if (selectionModel.includes(ind)) {
            claim[bulkEditMode] = bulkEdittedVal;
            if (bulkEdittedVal === 'Deferred') {
              claim.isSubmittable = false;
            }
          }
          return claim;
        })
        setClaimsList(currClaimsList);
        setSnackbar({
          children: "Bulk edit success.",
          severity: "success",
        });
      } else if (bulkEditMode === 'placeOfServiceCode') {
        await updateClaimData(currClaimsList.current.filter((claim, index) => selectionModel.includes(index)).map((claim) => claim.claim_ID), bulkEditMode, bulkEdittedVal);
        currClaimsList.current = currClaimsList.current.map((claim, ind) => {
          if (selectionModel.includes(ind)) {
            claim[bulkEditMode] = bulkEdittedVal;
          }
          return claim;
        })
        setClaimsList(currClaimsList);
        setSnackbar({
          children: "Bulk edit success.",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackbar({
        children: error.message,
        severity: "error"
      });
    }
    setBulkEditModal(false);
    setBulkEditMode("");
    setBulkEdittedVal("");
  }

  async function addBillingNote(id, note) {
    try {
      if (note && note.replace(/\s/g, '').length !== 0) {
        await axios.post(APIURL + "/accountsReceivable/billingNote", {
          claim_id: id,
          billing_note: note,
          empID: props.user.emp_id,
        });
        currClaimsList.current = currClaimsList.current.map((claim) => {
          if (claim.claim_ID === id) {
            claim.billing_note = note;
          }
          return claim;
        })
        console.log(currClaimsList.current);
        setClaimsList(currClaimsList);
        setSnackbar({
          children: "Note successfully saved.",
          severity: "success",
        });
      } else {
        setSnackbar({
          children: "Note cannot be empty",
          severity: "error"
        });
      }
    } catch (error) {
      setSnackbar({
        children: error.message,
        severity: "error"
      });
    }
    setEditNote(false);
    setEditRow(null);
  }

  function handleConfirmModal() {
    let notSubmittableClaims = claimsList.filter((r) => !r.isSubmittable).map((r) => r.id);
    if (selectionModel.length !== 0 && selectionModel.some((id) => notSubmittableClaims.includes(id))) {
      let finalValidIds = selectionModel.filter((id) => !notSubmittableClaims.includes(id));
      setSelectionModel(finalValidIds);
      if (finalValidIds.length !== 0) {
        setShowModal(!showModal);
      }
      else {
        setShowModal(false);
        setSnackbar({
          children: "Only claims with 'Ready to Bill' status and without errors are submittable, please fix them before submitting.",
          severity: "error",
        })
      }
    }
    else {
      setShowModal(!showModal);
    }
  }

  function handleDelConfirmModal() {
    setShowDelModal(!showDelModal);
  }

  function getBackgroundColor(color, mode) {
    return mode === "dark" ? darken(color, 0.6) : lighten(color, 0.8);
  }

  function getHoverBackgroundColor(color, mode) {
    return mode === "dark" ? darken(color, 0.5) : lighten(color, 0.7);
  }

  function onSelect(id) {
    let selectedClaim = claimsList.find(
      (claim) => claim.id === id
    );
    setSelectedClaims(selectedClaim);
  }

  function toggleCSVAdd() {
    setCsvAdd(!csvAdd);
  }

  function renderCSVModal() {
    return (
      <Upload
        showUpload={csvAdd}
        setCsvAdd={setCsvAdd}
        toggleCSVAdd={toggleCSVAdd}
        user={props.user}
      ></Upload>
    );
  }

  function onDateChange(newValue) {
    console.log("start DATE CHANGE");
    setCurrMonth(moment(newValue).startOf("month"));
    console.log(payerList);
    setCurrMonthEnd(moment(newValue).endOf("month"));
    console.log("end DATE CHANGE");
  }

  function getSelectButton() {
    return (
      <div className="flex-item-d">
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Tooltip title="" disabled={isLoading || isLoadingApiCall || currClaimsList.current.filter((claim) => claim.isSubmittable === true && moment(claim.CreateDate).month() === currentMonth.month() && moment(claim.CreateDate).year() === currentMonth.year()).length === 0}>
            <Button
              variant="contained"
              className={classes.button}
              fullWidth={true}
              onClick={() => {
                setSelectValidClaims(true);
              }}
              disabled={
                isLoading ||
                isLoadingApiCall ||
                currClaimsList.current.filter(
                  (claim) =>
                    claim.isSubmittable === true &&
                    moment(claim.CreateDate).month() === currentMonth.month() &&
                    moment(claim.CreateDate).year() === currentMonth.year()
                ).length === 0
              }
            >
              Select Fileable Claims
            </Button>
          </Tooltip>
        </Box>
      </div>
    );
  }

  function getUnselectButton() {
    return (
      <div className="flex-item-d1">
        <Box display="flex">
          <Button
            variant="contained"
            className={classes.button}
            sx={{ width: "80%" }}
            onClick={() => {
              setSelectValidClaims(false);
              setSelectionModel([]);
            }}
            style={{
              backgroundColor: "white",
              color: "grey",
            }}
          >
            Unselect All
          </Button>
        </Box>
      </div>
    );
  }

  const handleCellDoubleClick = (params) => {
    if (params.field === "billingNote") {
      setEditNote(true);
      setEditRow(params.row);
      setNoteText(params.row.billingNote);
    }
    if (selectionModel.length > 1 && (params.field === "Status" || params.field === "authAmount" || params.field === "placeOfServiceCode")) {
      setBulkEditMode(params.field);
      setBulkEditModal(true);
    }
    if (params.field === "Status" && selectionModel.length <= 1 && !['Processed as Primary', 'Processed as Secondary', 'Denied'].includes(params.value)) {
      setEditStatusMode(true);
    }
  };

  function getSendButton() {
    return (
      <div className="flex-item-d2">
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => {
              handleConfirmModal();
            }}
            style={{
              backgroundColor: "green",
            }}
          >
            Send
          </Button>
        </Box>
      </div>
    );
  }

  function getClaimsList() {
    let displayClaims = currClaimsList.current;
    displayClaims = displayClaims.filter(
      (claim) =>
        moment(claim.CreateDate).month() === currentMonth.month() &&
        moment(claim.CreateDate).year() === currentMonth.year()
    );
    let rows = displayClaims.map((claim) => {
      let claimDets = {
        id: claim.id,
        claim_ID: claim.claim_ID,
        DDDID: claim.DDDID,
        FirstName: claim.FirstName,
        payerId: claim.payerId,
        LastName: claim.LastName,
        DateOfBirth: moment(claim.DateOfBirth).isValid() ? moment(claim.DateOfBirth).utc().format("L") : "",
        Gender: claim.Gender,
        memberMedicaid: claim.memberMedicaid,
        priorAuth: claim.priorAuth,
        procedureCode: claim.procedureCode,
        procedureModifierCode: claim.procedureModifierCode,
        startDate: moment(claim.startDate).isValid() ? moment(claim.startDate).utc().format("L") : "",
        endDate: moment(claim.endDate).isValid() ? moment(claim.endDate).utc().format("L") : "",
        DiagnosisCode: claim.DiagnosisCode,
        authUnits: claim.authUnits,
        authAmount: claim.authAmount,
        totalCharges: claim.authAmount,
        errors: claim.errors,
        Status: claim.Status,
        Response: claim.Response,
        CreateDate: claim.CreateDate,
        LastResponseDate: claim.LastResponseDate
          ? (moment(claim.LastResponseDate).isValid() ? moment(claim.LastResponseDate).utc().format("L") : "")
          : "",
        errCode: claim.errCode,
        errDescription: claim.errDescription,
        isSubmittable: claim.isSubmittable,
        billingNoteId: claim.billing_note_id,
        billingNote: claim.billing_note,
        patientAddrLine1: claim.patientAddrLine1,
        patientCity: claim.patientCity,
        patientState: claim.patientState,
        patientZip: claim.patientZip,
        patientRel: claim.patientRel,
        placeOfServiceCode: claim.placeOfServiceCode,
      };
      return claimDets;
    });
    let cols = [
      {
        field: "id",
        headerName: "ID",
        width: 50,
        hide: true,
      },
      {
        field: "claim_ID",
        headerName: "ClaimID",
        hide: true,
      },
      {
        field: "DDDID",
        headerName: "DDDID",
        width: 80,
        hide: false,
        editable: true,
      },
      {
        field: "payerId",
        headerName: "Payer Id",
        width: 80,
        hide: false,
        editable: true,
      },
      {
        field: "FirstName",
        headerName: "First Name",
        width: 150,
        hide: false,
        editable: true,
        headerAlign: "center",
        renderHeader: (params) => {
          return (
            <div
              style={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
              }}
            >
              Patient First Name
            </div>
          );
        },
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <div class="crop">{params.value}</div>
            </Tooltip>
          );
        },
      },
      {
        field: "LastName",
        headerName: "Patient Last Name",
        width: 150,
        hide: false,
        editable: true,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <div class="crop">{params.value}</div>
            </Tooltip>
          );
        },
      },
      {
        field: "DateOfBirth",
        headerName: "DOB",
        width: 100,
        hide: false,
        editable: true,
      },
      {
        field: "Gender",
        headerName: "Gender",
        width: 65,
        hide: false,
        editable: true,
      },
      {
        field: "patientAddrLine1",
        headerName: "Address Line 1",
        width: 150,
        hide: false,
        editable: true,
      },
      {
        field: "patientCity",
        headerName: "City",
        width: 90,
        hide: false,
        editable: true,
      },
      {
        field: "patientState",
        headerName: "State",
        width: 65,
        hide: false,
        editable: true,
      },
      {
        field: "patientZip",
        headerName: "Zip",
        width: 80,
        hide: false,
        editable: true,
      },
      {
        field: "patientRel",
        headerName: "Relationship",
        width: 65,
        hide: false,
        editable: true,
      },
      {
        field: "priorAuth",
        headerName: "Prior Auth",
        width: 120,
        hide: false,
        editable: true,
        renderHeader: (params) => {
          return (
            <div
              style={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                verticalAlign: "bottom",
              }}
            >
              Prior Authorization
            </div>
          );
        },
      },
      {
        field: "DiagnosisCode",
        width: 85,
        hide: false,
        headerName: "Diagnosis Code",
        editable: true,
        renderHeader: (params) => {
          return (
            <div
              style={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                verticalAlign: "bottom",
              }}
            >
              Diagnosis Code
            </div>
          );
        },
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <div class="crop">{params.value}</div>
            </Tooltip>
          );
        },
      },
      {
        field: "memberMedicaid",
        headerName: "Medicaid ID",
        width: 120,
        hide: false,
        editable: true,
      },
      {
        field: "startDate",
        headerName: "Task Start Date",
        width: 120,
        hide: false,
        editable: true,
        sortComparator: dateComparator,
      },
      {
        field: "endDate",
        headerName: "Task End Date",
        width: 120,
        hide: false,
        editable: true,
        sortComparator: dateComparator,
      },
      {
        field: "placeOfServiceCode",
        headerName: "Place of service code",
        width: 165,
        hide: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: [{label: '0 - Emergency Room (23)', value: '23'}, { label: "1 - Doctor's Office (11)", value: '11' }, { label: "2 - Patient's Home (12)", value: '12' }, { label: "3 - Inpatient Hospital (21)", value: '21' }, { label: '4 - Boarding Home (14)', value: '14' }, { label: "5 - Skilled Nursing Home (31)", value: '31' }, { label: "6 - Independent Laboratory (81)", value: '81' }, { label: "7 - Outpatient Hospital (22)", value: '22' }, { label: "8 - Clinic (49)", value: '49' }, { label: "9 - Other (99)", value: '99' }]
      },
      {
        field: "authAmount",
        headerName: "Authorized Amount",
        width: 80,
        hide: false,
        editable: true,
        renderHeader: (params) => {
          return (
            <div
              style={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                verticalAlign: "bottom",
              }}
            >
              Auth Amount
            </div>
          );
        },
      },
      {
        field: "authUnits",
        headerName: "Auth Units",
        width: 60,
        hide: false,
        editable: true,
        renderHeader: (params) => {
          return (
            <div
              style={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                verticalAlign: "bottom",
              }}
            >
              Auth Units
            </div>
          );
        },
      },
      {
        field: "procedureCode",
        headerName: "Procedure Code",
        width: 100,
        editable: true,
        hide: false,
        renderHeader: (params) => {
          return (
            <div
              style={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                verticalAlign: "bottom",
              }}
            >
              Procedure Code
            </div>
          );
        },
      },
      {
        field: "procedureModifierCode",
        headerName: "Procedure Code Modifier",
        width: 110,
        editable: true,
        hide: false,
        renderHeader: (params) => {
          return (
            <div
              style={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                verticalAlign: "bottom",
              }}
            >
              Procedure Code Modifier
            </div>
          );
        },
      },
      {
        field: "totalCharges",
        headerName: "Total Charges",
        width: 80,
        hide: false,
        renderHeader: (params) => {
          return (
            <div
              style={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                verticalAlign: "bottom",
              }}
            >
              Total Charges
            </div>
          );
        },
      },
      {
        field: "errors",
        headerName: "Claim Errors",
        width: 140,
        hide: false,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <span style={{ overflow: "hidden" }}>{params.value}</span>
            </Tooltip>
          );
        },
      },
      {
        field: "billingNote",
        headerName: "Notes",
        width: 300,
        editable: true,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <div class="crop">{params.value}</div>
            </Tooltip>
          );
        },
      },
      {
        field: "Status",
        headerName: "Claim Status",
        width: 200,
        hide: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: editStatusMode ? ['Ready to Bill', 'Deferred'] : ['Ready to Bill', 'Deferred', 'Processed as Primary', 'Released', 'Pending', 'Processed as Secondary', 'Denied']
      },
      {
        field: "Response",
        headerName: "Response Status",
        width: 130,
        hide: false,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <div class="crop">{params.value}</div>
            </Tooltip>
          );
        },
      },
      {
        field: "CreateDate",
        headerName: "Created Date",
        width: 130,
        hide: false,
        valueGetter: (value, row, column, apiRef) => ({
          createDate: row.CreateDate,
          procCode: row.procedureCode,
          procCodeModifer: row.procedureModifierCode,
          status: row.Status,
          isSubmittable: row.isSubmittable,
        }),
        valueFormatter: (value, row, column, apiRef) => moment(value.createDate).utc().format("MM/DD/YYYY"),
        sortComparator: dateProcCodeModifComparator,
      },
      {
        field: "LastResponseDate",
        headerName: "Last Response Date",
        width: 100,
        hide: false,
        sortComparator: dateComparator,
        renderHeader: (params) => {
          return (
            <div
              style={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                verticalAlign: "bottom",
              }}
            >
              Last Response Date
            </div>
          );
        },
      },
      {
        field: "errCode",
        headerName: "Error Code",
        width: 120,
        hide: false,
        renderCell: (params) => {
          let displayVal = (params.row.Status.includes('Processed')) ? "" : params.value;
          return (
            <Tooltip title={displayVal}>
              <div class="crop">{displayVal}</div>
            </Tooltip>
          );
        },
      },
      {
        field: "errDescription",
        headerName: "Error Description",
        width: 140,
        hide: false,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <div class="crop">{params.value}</div>
            </Tooltip>
          );
        },
      },
    ];

    let claimsListFin = (
      <Box
        sx={{
          height: '100vh - 500px',
          maxHeight: '100vh - 500px',
          width: 1,
          display: 'flex', flexDirection: 'column',
          // "& .MuiDataGrid-row.super-app-theme--READYTOBILL--true": {
          //   bgcolor: (theme) =>
          //     getBackgroundColor(theme.palette.info.light, theme.palette.mode),
          // },
          // "& .MuiDataGrid-row.super-app-theme--READYTOBILL--true:hover": {
          //   bgcolor: (theme) =>
          //     getHoverBackgroundColor(
          //       theme.palette.info.light,
          //       theme.palette.mode
          //     ),
          // },
          "& .MuiDataGrid-row.super-app-theme--ReadytoBill--false": {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.error.light, theme.palette.mode),
          },
          "& .MuiDataGrid-row.super-app-theme--ReadytoBill--false:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.error.light,
                theme.palette.mode
              ),
          },
          "& .MuiDataGrid-row.super-app-theme--CHECKPROCCODE": {
            bgcolor: (theme) =>
              getBackgroundColor(
                theme.palette.warning.light,
                theme.palette.mode
              ),
          },
          "& .MuiDataGrid-row.super-app-theme--CHECKPROCCODE:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.warning.light,
                theme.palette.mode
              ),
          },
          // "& .MuiDataGrid-row.super-app-theme--RELEASED--false": {
          //   bgcolor: (theme) =>
          //     getBackgroundColor(
          //       theme.palette.success.light,
          //       theme.palette.mode
          //     ),
          // },
          // "& .MuiDataGrid-row.super-app-theme--RELEASED--false:hover": {
          //   bgcolor: (theme) =>
          //     getHoverBackgroundColor(
          //       theme.palette.success.light,
          //       theme.palette.mode
          //     ),
          // },
          // "& .super-app-theme--ON_HOLD--false": {
          //   bgcolor: (theme) =>
          //     getBackgroundColor(
          //       theme.palette.warning.light,
          //       theme.palette.mode
          //     ),
          // },
          // "& .super-app-theme--ON_HOLD--false:hover": {
          //   bgcolor: (theme) =>
          //     getHoverBackgroundColor(
          //       theme.palette.warning.light,
          //       theme.palette.mode
          //     ),
          // },
          "& .MuiDataGrid-row.super-app-theme--ERROR--false": {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.error.light, theme.palette.mode),
          },
          "& .MuiDataGrid-row.super-app-theme--ERROR--false:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.error.light,
                theme.palette.mode
              ),
          },
          "& .MuiDataGrid-row.super-app-theme--ReadytoBill.Mui-selected": {
            backgroundColor: lighten("#1565c0", 0.8),
          },
          "& .MuiDataGrid-row.super-app-theme--ReadytoBill.Mui-selected:hover":
          {
            backgroundColor: lighten("#1565c0", 0.6),
          },
        }}
      >
      <div style={{ height:'calc(100vh - 200px)', width: '100%' }}>
        <DataGridPro
          initialState={{
            sorting: {
              sortModel: [{ field: "CreateDate", sort: "desc" }],
            },
            pagination: { paginationModel: { pageSize: 25 } },
            density: "compact"
          }}
          columnHeaderHeight={100}
          onRowSelected={(params) => console.log(params)}
          justifyContent="center"
          checkboxSelection={true}
          rowSelectionModel={selectionModel}
          isRowSelectable={(params) => params.row.isSubmittable || (!params.row.isSubmittable && params.row.Status === "Ready to Bill")}
          onRowSelectionModelChange={(ids) => {
            setSelectionModel(ids);
          }}
          onCellDoubleClick={(params, event) => {
            if (params.field === 'Status' && selectionModel.length > 1) {
              event.defaultMuiPrevented = true;
            }
            if (params.field === 'Status' && !['Ready to Bill', 'Deferred'].includes(params.value)) {
              event.defaultMuiPrevented = true;
            }
            if (params.field === 'placeOfServiceCode' && selectionModel.length > 1) {
              event.defaultMuiPrevented = true;
            }
            handleCellDoubleClick(params);
          }}
          rows={rows}
          columns={cols}
          // columnVisibilityModel={{
          //   id: false,
          //   claim_ID: false,
          // }}
          // showToolbar
          getRowClassName={(params) => {
            if (!params.row.isSubmittable) {
              return `super-app-theme--${String(params.row.Status).replaceAll(
                " ",
                ""
              )}--${params.row.isSubmittable}`;
            } else {
              let mods = params.row.procedureModifierCode;
              if (typeof mods !== "string" || mods === "null") {
                mods = "";
              }
              mods = mods.split(":");
              // console.log(params.row.procedureCode.length)
              if (
                (mods[0] === "" &&
                  mods.length === 1 &&
                  params.row.procedureCode.length === 5) ||
                (mods[0] !== "" &&
                  mods.filter((m) => m.length === 2).length === mods.length &&
                  params.row.procedureCode.length === 5)
              ) {
                return `super-app-theme--${params.row.Status}--${params.row.isSubmittable}`;
              } else {
                return `super-app-theme--CHECKPROCCODE`;
              }
            }
          }}
          slots={{
            toolbar: (params) => {
              return (
                <GridToolbarContainer>
                  <GridToolbarColumnsButton />
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                  <GridToolbarExport />
                  <Box display="flex" style={{ flex: 1, flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end" }}>
                    <Button disabled={isLoading || isLoadingApiCall} onClick={handleAddRow} style={{}} variant="contained" className={classes.button}>
                      Add a claim
                    </Button>
                    <Button disabled={isLoading || isLoadingApiCall || selectionModel.length === 0} onClick={handleDelConfirmModal} style={{ marginLeft: '5px' }} variant="contained" className={classes.button}>
                      Delete claim
                    </Button>
                  </Box>
                </GridToolbarContainer>
              );
            }, color: "#3fadf6"
          }}
          pagination
          pageSizeOptions={[25, 50, 100]}
          onRowClick={(params) => onSelect(params.id)}
          isCellEditable={(params) => !["Released"].includes(params.row.Status)}
          processRowUpdate={(updatedRow, originalRow) => {
            return handleCellEditCommit(updatedRow, originalRow);
          }}
          slotProps={{
            cell: {
              onBlur: (params) => setEditStatusMode(false),
            },
          }} />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </div>
      </Box>
    );
    return claimsListFin;
  }

  return (
    <div>
      <Typography
        style={{
          fontWeight: "bold",
          fontSize: "1.5rem",
          fontFamily: "Lato",
          marginBottom: 10,
        }}
      >
        Claims
      </Typography>
      <div
        id="claims-main"
        style={{ height: "100%", width: "calc(100vw - 500px)", backgroundColor: "#fafafa" }}
        className="d-flex row justify-content-start"
      >
        <div
          style={{ height:"100%", marginTop: 30, width: "calc(100vw - 500px)" }}
          className="col-lg-12 col-sm-7 d-flex flex-column justify-content-start"
        >
          {!isLoading && (
            <div className="flex-container" style={{ display: "flex" }}>
              <div
                className="flex-item"
                style={{
                  flexDirection: "row-reverse",
                  height: "40px",
                  marginTop: "5px",
                  marginBottom: "20px",
                  marginLeft: "20px",
                }}
              >
                <Button
                  size="large"
                  className="DayButton"
                  onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                >
                  <i className="fas fa-arrow-left"></i>
                </Button>
                <LocalizationProvider
                  class="DatePickerParent"
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Select Date"
                    views={["year", "month"]}
                    value={new Date(currentMonth)}
                    onChange={(newValue) => {
                      onDateChange(newValue);
                    }}
                    sx={{marginTop: '10px'}}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </LocalizationProvider>
                <Button
                  size="large"
                  className="DayButton"
                  onClick={() => onDateChange(currentMonth.add(1, "month"))}
                >
                  <i className="fas fa-arrow-right"></i>
                </Button>
              </div>
              <div className="right">
                <div className="flex-item-u">
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Button
                      variant="contained"
                      className={classes.button}
                      style={{ marginLeft: "-50px" }}
                      // className={`btn btn-sm btn-primary w-25 ${
                      //   props.user.type === "Fieldworker" ||
                      //   props.user.type === "Support Coordinator"
                      //     ? "d-none"
                      //     : ""
                      // }`}
                      onClick={() => {
                        setCsvAdd(!csvAdd);
                      }}
                    >
                      Upload Claims
                    </Button>
                  </Box>
                </div>
                {!selectValidClaims &&
                  selectionModel.length === 0 &&
                  getSelectButton()}
                {(selectValidClaims || selectionModel.length !== 0) &&
                  getUnselectButton()}
                {(selectValidClaims || selectionModel.length !== 0) &&
                  getSendButton()}
              </div>
            </div>
          )}

          {!isLoading && claimsList.length !== 0 && getClaimsList()}
          {!isLoading && claimsList.length === 0 && (
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ height: "500px", marginTop: -30 }}
            >
              <h5
                style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
                className="text-center"
              >
                You haven’t added any claims yet{" "}
              </h5>
            </div>
          )}
          {!!isLoading && (
            <div className="d-flex flex-row w-100 justify-content-center mt-5">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!!isLoadingApiCall && (
            <div class="overlay">
              <div class="overlay__inner">
                <div class="overlay__content">
                  <span class="spinner"></span>
                </div>
                <div class="overlay__content__text">
                  Please wait, your request is being processed!
                </div>
              </div>
            </div>
          )}
          {editNote &&
            <Modal show={editNote} onHide={() => { setEditNote(false); setEditRow(null); }} size="lg" backdrop="static" keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>{editRow.billingNoteId ? 'Edit Note' : 'Add Note'}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <CssTextField
                    multiline
                    value={noteText}
                    onChange={(event) => {
                      setNoteText(event.target.value);
                    }}
                    style={{ margin: '5px', width: '100%' }}
                    inputProps={{ maxLength: 2000 }}
                    minRows={8}
                    label="Note"
                    variant="outlined"
                    id="bNote"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <RBButton
                  style={{
                    width: '100%',
                    padding: '5px',
                    margin: '5px',
                  }}
                  onClick={() => editRow.billingNoteId ? updateBillingNote(editRow.billingNoteId, noteText, editRow.claim_ID) : addBillingNote(editRow.claim_ID, noteText)}
                >
                  {editRow.billingNoteId ? 'Edit Note' : 'Add Note'}
                </RBButton>
              </Modal.Footer>
            </Modal>
          }
          {bulkEditModal &&
            <Modal show={bulkEditModal} onHide={() => { setBulkEditMode(""); setBulkEditModal(false); }} size="sm" backdrop="static" keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>{`Bulk edit ${bulkEditMode === 'Status' ? 'status' : bulkEditMode === 'authAmount' ? 'auth amount' : 'place of service'}`}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  {bulkEditMode === 'Status' && <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={bulkEdittedVal}
                        label="Status"
                        onChange={(event) => {
                          setBulkEdittedVal(event.target.value);
                        }}
                      >
                        <MenuItem value={"Deferred"}>Deferred</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>}
                  {bulkEditMode === 'authAmount' && <div><p style={{ display: "inline", fontSize: "12px", color: "grey" }}><i>Only decimal values are allowed.</i></p>
                    <CssTextField
                      value={bulkEdittedVal}
                      onChange={(event) => {
                        setBulkEdittedVal(event.target.value);
                      }}
                      style={{ width: '100%', marginTop: "10px" }}
                      inputProps={{ maxLength: 50 }}
                      minRows={8}
                      label='Auth Amount'
                      variant="outlined"
                      id="bNote"
                    /></div>}
                    {bulkEditMode === 'placeOfServiceCode' && <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel>Place of Service Code</InputLabel>
                      <Select
                        value={bulkEdittedVal}
                        label="Place of Service Code"
                        onChange={(event) => {
                          setBulkEdittedVal(event.target.value);
                        }}
                      >
                        <MenuItem value={"23"}>0 - Emergency Room (23)</MenuItem>
                        <MenuItem value={"11"}>1 - Doctor's Office (11)</MenuItem>
                        <MenuItem value={"12"}>2 - Patient's Home (12)</MenuItem>
                        <MenuItem value={"21"}>3 - Inpatient Hospital (21)</MenuItem>
                        <MenuItem value={"14"}>4 - Boarding Home (14)</MenuItem>
                        <MenuItem value={"31"}>5 - Skilled Nursing Home (31)</MenuItem>
                        <MenuItem value={"81"}>6 - Independent Laboratory (81)</MenuItem>
                        <MenuItem value={"22"}>7 - Outpatient Hospital (22)</MenuItem>
                        <MenuItem value={"49"}>8 - Clinic (49)</MenuItem>
                        <MenuItem value={"99"}>9 - Other (99)</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <RBButton
                  style={{
                    width: '100%',
                    padding: '5px',
                    margin: '5px',
                  }}
                  onClick={() => bulkUpdateSelected()}
                >
                  {bulkEditMode === 'Status' ? 'Update Status' : bulkEditMode === 'authAmount' ?  'Update Auth Amount' : 'Update place of service'}
                </RBButton>
              </Modal.Footer>
            </Modal>
          }
          {renderCSVModal()}
        </div>
        <Modal show={showModal} onHide={() => {
          setShowModal(false);
        }}>
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center">SUBMIT CLAIMS</Modal.Title>
          </Modal.Header>
          <center>
            <Modal.Body>
              You have selected {selectionModel.length} claims.
            </Modal.Body>
            <Modal.Body>
              Are you sure you want to send the selected claims?
            </Modal.Body>
          </center>
          {/* <Modal.Footer> */}
          <div className="d-flex flex-row justify-content-center m-3">
            <Button
              variant="contained"
              onClick={() => {
                setShowModal(false);
              }}
              className={classes.button}
              style={{
                backgroundColor: "white",
                color: "grey",
              }}
            >
              Back
            </Button>
            <Box style={{ width: "30px" }}></Box>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                setNextStep(true);
                handleConfirmModal();
              }}
              style={{
                backgroundColor: "green",
              }}
            >
              Yes
            </Button>
          </div>
          {/* </Modal.Footer> */}
        </Modal>
        <Modal show={showDelModal} onHide={handleDelConfirmModal}>
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center">DELETE CLAIMS</Modal.Title>
          </Modal.Header>
          <center>
            <Modal.Body>
              You have selected {selectionModel.length} claims.
            </Modal.Body>
            <Modal.Body>
              Are you sure you want to delete the selected claims?
            </Modal.Body>
          </center>
          {/* <Modal.Footer> */}
          <div className="d-flex flex-row justify-content-center m-3">
            <Button
              variant="contained"
              onClick={handleDelConfirmModal}
              className={classes.button}
              style={{
                backgroundColor: "white",
                color: "grey",
              }}
            >
              Back
            </Button>
            <Box style={{ width: "30px" }}></Box>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                setDelClaim(true);
                handleDelConfirmModal();
              }}
              style={{
                backgroundColor: "green",
              }}
            >
              Yes
            </Button>
          </div>
          {/* </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
}