import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import Favorites from "./Favorites";
import CM_Reports from "./CM_Reports";
import SC_Reports from "./SC_Reports";
import DC_Reports from "./DC_Reports";
import SP_Reports from "./SP_Reports";
import Billing from "./Billing";
import Company from "./Company";
import TimesheetsReport from "./TimesheetsReport";
import EVV_Reports from "./EVV_Reports";
import CMCustomerReports from "./CMCustomerReports";
import Scheduler_Reports from "./Scheduler_Reports";
import DemographicsDashboard from "./DemographicsDashboard";
import OperationsDashboard from "./OperationsDashboard";
import HRDashboard from "./HRDashboard";
import FinancialDashboard from "./FinancialDashboard";

export default function Reports(props) {
    // { "primaryBusinessType": "Support Coordination", "featureValue": 101 },
    // { "primaryBusinessType": "Service Providers", "featureValue": 102 },
    // { "primaryBusinessType": "Case Management", "featureValue": 104 },
    // { "primaryBusinessType": "Daycare Centers", "featureValue": 105 },
    const pBusinessType = props.user?.comp_settings.find(setting => setting.feature_value === 'primaryBusinessType') ? props.user.comp_settings.filter((setting) => setting.feature_value === "primaryBusinessType")[0].feature_id : 101;
    const isAdmin = props.user.type === 'Admin';

    const getDefaultSubKey = () => {
        switch (pBusinessType) {
            case 101: return 'mtSummary';
            case 102: return 'planRenewal';
            case 104: return 'employeeNotes';
            case 105: return 'planRenewal';
            default: return 'mtSummary';
        }
    };

    const [activeKey, setActiveKey] = useState('favorites');
    const [activeSubKey, setActiveSubKey] = useState(getDefaultSubKey());

    const handleKeyChange = (event, activeKey) => {
        setActiveKey(activeKey);

        if (activeKey === 'evv') {
            setActiveSubKey('evvReport');
        } else if (activeKey === 'scheduleManagement') {
            setActiveSubKey('clientAttendance');
        } else {
            setActiveSubKey(getDefaultSubKey());
        }
    };

    const navigateToFavoriteReport = (activeKey, activeSubKey) => {
        setActiveKey(activeKey);
        setActiveSubKey(activeSubKey);
    };

    const getAdministrationComponent = () => {
        switch (pBusinessType) {
            case 101: return SC_Reports;
            case 102: return SP_Reports;
            case 104: return CM_Reports;
            case 105: return DC_Reports;
            default: return null;
        }
    };

    const tabs = [
        { key: 'favorites', label: "Favorites", component: Favorites },
        { key: 'administration', label: "Administration", component: getAdministrationComponent() },
        { key: 'timeManagement', label: "Time Management", component: TimesheetsReport },
        ...(isAdmin ? [{ key: 'billing', label: "Billing", component: Billing }] : []),
        ...(isAdmin ? [{ key: 'company', label: "Company", component: Company }] : []),
        { key: 'evv', label: "EVV", component: EVV_Reports },
        ...(pBusinessType === 104 ? [{ key: 'caseManagement', label: "Case Management", component: CMCustomerReports }] : []),
        ...(pBusinessType === 105 ? [{ key: 'scheduleManagement', label: "Schedule Management", component: Scheduler_Reports }] : []),
        { key: 'demographicsDashboard', label: "Demographics Dashboard", component: DemographicsDashboard },
        { key: 'operationsDashboard', label: "Operations Dashboard", component: OperationsDashboard },
        { key: 'hrDashboard', label: "HR Dashboard", component: HRDashboard },
        { key: 'financialDashboard', label: "Financial Dashboard", component: FinancialDashboard },
    ];

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Box sx={{ flexGrow: 1, display: 'flex', height: 'calc(100vh - 85px)' }}>
                <Tabs
                    value={activeKey}
                    onChange={handleKeyChange}
                    orientation="vertical"
                    sx={{
                        borderRight: 1,
                        borderColor: 'divider',
                        width: '15%',
                        '& .Mui-selected': {
                            backgroundColor: '#f0f0f0',
                            fontWeight: 'bold',
                        },
                        '& .MuiTab-root': {
                            alignItems: 'flex-start',
                            textAlign: 'left',
                        },
                    }}
                >
                    {tabs.map((tab, index) => (
                        <Tab key={tab.key} value={tab.key} label={tab.label} />
                    ))}
                </Tabs>

                <Box sx={{ p: ['demographicsDashboard', 'operationsDashboard', 'hrDashboard', 'financialDashboard'].includes(activeKey) ? 0 : 1 }}>
                    {tabs.find(tab => tab.key === activeKey)?.component &&
                        React.createElement(tabs.find(tab => tab.key === activeKey).component,
                            { ...props, navigateToFavoriteReport, activeSubKey })}
                </Box>
            </Box>
        </Box>
    );
};