import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Autocomplete, ListItemText } from '@mui/material';
import axios from 'axios';
import { APIURL } from '../../../config';

function AddResourceModal({ open, onClose, locations, contacts, isDayCare, onAddResource }) {
  const [resourceName, setResourceName] = useState('');
  const [capacity, setCapacity] = useState('');
  const [location, setLocation] = useState(null);
  const [contact, setContact] = useState(null);
  const [notes, setNotes] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    resourceName: false,
    location: false,
    capacity: false,
    contact: false
  });

  const validate = () => {
    let errors = {
      resourceName: resourceName === '',
      location: location === null,
      capacity: capacity === ''
    };
    setErrors(errors);
    setSubmitted(true);
    return !Object.values(errors).some((err) => err);
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }
    try {
        const response = await axios.post(`${APIURL}/resources`, {
            resource_name: resourceName,
            capacity: capacity,
            location_id: location ? location.loc_id : null,
            contact_id: contact ? contact.con_id : null,
            notes: notes,
            resource_is_active: true,
        });

      let id = response.data
      
      onAddResource({
        resource_id: id,
        resource_name: resourceName,
        capacity: capacity,
        location_id: location ? location.loc_id : null,
        contact_id: contact ? contact.con_id : null,
        notes: notes,
        resource_is_active: true,
        location: location,
        contact: contact
    });
      onClose();
    } catch (error) {
      console.error('Failed to create resource:', error);
    }
  };

  useEffect(() => {
    if (!open) {
      setResourceName('');
      setCapacity('');
      setLocation(null);
      setContact(null);
      setNotes('');
      setSubmitted(false);
      setErrors({
        resourceName: false,
        location: false,
        capacity: false,
        contact: false
      });
    }
  }, [open]);

  const resourceLabelSingle = isDayCare ? 'Location' : 'Resource';
  const locationLabel = isDayCare ? 'Location' : 'Resource Location';

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-resource-modal-title"
      aria-describedby="add-resource-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        maxWidth: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        maxHeight: '90vh',
        overflowY: 'auto'
      }}>
        <Typography id="add-resource-modal-title" variant="h6" sx={{ mb: 1 }}>
          Add a New {resourceLabelSingle}
        </Typography>
        <TextField
          label={`${resourceLabelSingle} Name`}
          value={resourceName}
          onChange={(e) => setResourceName(e.target.value)}
          fullWidth
          sx={{ my: 2 }}
          error={errors.resourceName && submitted}
        />
        <Autocomplete
          id="location"
          options={locations}
          getOptionLabel={(option) =>
            `${option.loc_addr_line1}, ${option.loc_addr_line2}, ${option.loc_city}, ${option.loc_state}, ${option.loc_zip}`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={locationLabel}
              error={errors.location && submitted}
              sx={{ my: 2 }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.loc_id}>
              <ListItemText
                primary={`${option.loc_addr_line1}, ${option.loc_addr_line2}, ${option.loc_city}, ${option.loc_state}, ${option.loc_zip}`}
              />
            </li>
          )}
          value={location}
          onChange={(event, newValue) => {
            setLocation(newValue);
          }}
        />
        <Autocomplete
          id="contact"
          options={contacts}
          getOptionLabel={(option) => option.con_first_name + ' ' + option.con_last_name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Contact"
              sx={{ my: 2 }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.con_id}>
              <ListItemText primary={option.con_first_name + ' ' + option.con_last_name} />
            </li>
          )}
          value={contact}
          onChange={(event, newValue) => {
            setContact(newValue);
          }}
        />
        <TextField
          label="Capacity"
          type="number"
          value={capacity}
          onChange={(e) => setCapacity(e.target.value)}
          fullWidth
          inputProps={{ max: 1000, min: 1 }}
          error={errors.capacity && submitted}
          sx={{ my: 2 }}
        />
        <TextField
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          fullWidth
          multiline
          rows={4}
          sx={{ my: 2 }}
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2 }}
        >
          Add
        </Button>
      </Box>
    </Modal>
  );
}

export default AddResourceModal;
