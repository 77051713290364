import React, { useState, useEffect } from 'react';
import * as msal from "@azure/msal-browser";
import axios from 'axios'
import './App.css';
import { MUI_KEY } from './config';
//import { LicenseInfo } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import Navbar from './container/NavBar/NavBar';
import GeneralSidebar from './container/GeneralSidebar/GeneralSidebar';
import Landing from './container/Landing/Landing';
import EmailLanding from './container/Landing/emailLanding';
import moment from 'moment';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { SEGMENTAPIKEY, env, APIURL, CLIENT_ID, MSTENANT, SCOPE } from './config';
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate  } from '@azure/msal-react';
import CircularProgress from '@mui/material/CircularProgress';
import UserSelectionDialog from './components/Auth/UserSelectionDialog';
import { Switch, Route } from 'react-router-dom';
import IntakeForm from './container/GeneratedPages/Forms/IntakeForm';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY });
LicenseInfo.setLicenseKey(MUI_KEY);
axios.defaults.headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  'Access-Control-Allow-Origin': '*',
  "Access-Control-Allow-Headers": '*',
  "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
  "Access-Control-Max-Age": 10000,
  'Authorization': 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
}

const App = (props) => {

  useEffect(() => {
    const refreshToken = async () => {
      const account = props.instance.getActiveAccount();
      if (!account) {
        return;
      }
    
      const silentRequest = {
        forceRefresh: true,
        scopes: [SCOPE],
        account: account
      };
    
      try {
        const silentResult = await props.instance.acquireTokenSilent(silentRequest);
        console.log(silentResult.accessToken);
        window.localStorage.setItem('Fw4_access_Token', silentResult.accessToken);
      } catch (error) {
        console.error("Failed to acquire token silently:", error);
      }
    };

    // Call refreshToken immediately and then every 15 minutes
    refreshToken();
    const intervalId = setInterval(refreshToken, 10 * 60 * 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [props.instance]);

  return (
    <MsalProvider instance={props.instance}>
      <Switch>
        <Route path="/form/:token" component={IntakeForm} />
        <Route path="/" component={MainContent} />
      </Switch>
    </MsalProvider>
  );
};

const MainContent = (props) => {
  
  const [state, setState] = useState({
    isAuth: false,
    user: {},
    isLoading: false,
    cred: {},
    subscription: true,
    signinBtn: true,
    redirectedFromEmail: false,
    modal: false,
    response: null, // Add response to the state
  });

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  useEffect(async() => {
    if (!window.localStorage.getItem('Fw4_access_Token') && window.location.pathname !== "/") {
      window.location.href = '/';
    }

    if (env === 'prod') {
      window.document.getElementById('cio-tracker').setAttribute('data-site-id', 'b2894d25baad5a8ad3c2');
    } else {
      window.document.getElementById('cio-tracker').setAttribute('data-site-id', '56e083c08b1a5e933c5a');
    }

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('source') === 'email') {
      setState({ ...state, redirectedFromEmail: true });
    }
  }, []);

  useEffect(() => {
    if (!!window.localStorage.getItem('Fw4_access_Token') && window.location.pathname === "/home") {
      setTimeout(() => {
        window.history.pushState({}, null, '/');
      }, 2000);
    }
  });

  useEffect(() => {
    const checkExistingUser = async() => {
      if (instance) {
        const accounts = instance.getAllAccounts();
        if(accounts.length > 0) {
          await instance.setActiveAccount(accounts[0]);
          console.log(accounts[0]);
          instance.acquireTokenSilent({ scopes: [SCOPE] })
          .then(async(response) => {
            await getUserDetails();
          })
          .catch(err => {
            instance.logoutPopup();
            console.log(err)
          });
        }
      }
    }

    setTimeout(checkExistingUser, 1);

}, [instance]);

  const checkTokenExpiry = async (fromMount = false) => {
    axios.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem('Fw4_access_Token')}`;

    if (!!instance.getAllAccounts().length && moment().isAfter(moment(window.localStorage.getItem('Fw4_access_Token_exp')).add(-1, 'm'))) {
      return await getUserDetails();
    }

    const accounts = instance.getAllAccounts();
    if (!!fromMount && accounts.length > 0 && moment().isBefore(moment(window.localStorage.getItem('Fw4_access_Token_exp')))) {
      await getUserFromDB({ accessToken: window.localStorage.getItem('Fw4_access_Token'), account: { userName: accounts[0].username } });
    } else {
      return true;
    }
  };

  const loginUser = async () => {
    if (state.modal) {
      logoutUser();
      return;
    }
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      try {
        const { account } = await instance.loginPopup({ scopes: ["User.Read"] });
        instance.setActiveAccount(account);
        await getUserDetails();
      } catch (err) {
        console.log(err);
      }
    } else {
      instance.setActiveAccount(accounts[0]);
      await getUserDetails();
    }
  };

  const getUserDetails = async () => {
    instance.acquireTokenSilent({ scopes: [SCOPE] })
      .then(async response => {
        window.localStorage.setItem('Fw4_access_Token', response.accessToken);
        window.localStorage.setItem('Fw4_access_Token_exp', moment(response.expiresOn).format('YYYY-MM-DDTHH:mm:ss'));
        await getUserFromDB(response);
        return true;
      })
      .catch(err => {
        if (state.modal) {
          window.location.href = '/';
          instance.logoutPopup();
        }
        if (!!window.localStorage.getItem('Fw4_access_Token') && Number(moment(window.localStorage.getItem('Fw4_access_Token_exp')).format('YYYYMMDDHHmm')) - Number(moment().format('YYYYMMDDHHmm')) > 0) {
          window.location.href = '/';
        }
        return false;
      });
  };

  const getUserFromDB = async (response) => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      'Authorization': 'Bearer ' + response.accessToken
    };

    let user = await axios.get(APIURL + '/auth', { params: { email: response.account.userName ? response.account.userName : response.account.username } });

    if (user.data.code == 2) {
      window.sessionStorage.clear();
      setState({ ...state, isAuth: false, modal: false, isLoading: false, subscription: false, signinBtn: false });
      setTimeout(() => {
        logoutUser();
      }, 10000);
    } else if (user.data.code == 4) {
      window.sessionStorage.clear();
      setState({ ...state, isAuth: false, modal: false, isLoading: false, subscription: true, signinBtn: false });
      alert("Your employee account has been terminated by your company admin.");
      setTimeout(() => {
        logoutUser();
      }, 1000);
    } else if (user.data.code == 1) {
      window.sessionStorage.clear();
      setState({ ...state, isAuth: false, modal: true, isLoading: false, subscription: true, signinBtn: false });
      setTimeout(() => {
        logoutUser();
      }, 5000);
    } else {
      if (user.data.length > 1) {
        setState(prevState => ({
          ...prevState,
          showUserSelectionDialog: true,
          users: user.data,
          response: response
        }));
      } else {
        console.log(user);
        if(user.data.length === 0){
          logoutUser();
        } else {
          handleUserSelection(user.data[0], response);
        }
      }
    }
  };


  const handleUserSelection = async (selectedUser, response) => {
    console.log(selectedUser)
    let settings = await axios.get(APIURL + '/config/settings', { params: { comp_id: selectedUser.emp_company_id } });

    setState(prevState => ({
      ...prevState,
      isAuth: true,
      modal: false,
      user: {
        ...prevState.user,
        isLoading: false,
        emp_id: selectedUser.emp_id,
        emp_name: selectedUser.emp_first_name + ' ' + selectedUser.emp_last_name,
        email: selectedUser.empEmail,
        sec_email: selectedUser.sec_email,
        comp_id: selectedUser.emp_company_id,
        company_name: selectedUser.com_name,
        company_logo: selectedUser.logo,
        type: selectedUser.emp_type,
        emp_is_admin: selectedUser.emp_is_admin,
        emp_manager_id: selectedUser.emp_manager_id,
        accessToken: response.accessToken,
        subs: selectedUser.subs,
        states: selectedUser.states,
        comp_settings: settings?.data,
      },
      showUserSelectionDialog: false
    }));

    var sha1 = require('sha1');
    let sessionID = window.localStorage.getItem('Fw4_access_Token');
    var hash = sha1(sessionID);
    var log_data = {
      email: selectedUser.empEmail,
      actionType: selectedUser.loginNum === 1 ? 'First Login' : 'New Login',
      actionSubType: selectedUser.emp_first_name + ' ' + selectedUser.emp_last_name,
      sessionID: hash
    };
    await axios.post(APIURL + "/activity_log", log_data);

    if (state.user && state.user.emp_id && state.user.sec_email && state.user.company_name && state.user.comp_id && state.user.emp_first_name && state.user.emp_last_name && state.user.emp_name) {
      window._cio.identify({
        id: String(state.user.emp_id),
        email: String(state.user.sec_email),
        company: String(state.user.company_name),
        company_id: String(state.user.comp_id),
        first_name: String(selectedUser.emp_first_name),
        last_name: String(selectedUser.emp_last_name),
        name: state.user.emp_name
      });
    }
  };
  
  const logoutUser = async () => {
    if (state.user.emp_id) {
      analytics.track('Signed Out', {
        name: state.user.emp_name,
        email: state.user.sec_email,
        groupId: String(state.user.comp_id),
        groupName: String(state.user.company_name),
      }, {
        userId: String(state.user.emp_id),
        context: {
          GROUP_ID: String(state.user.comp_id),
        },
      });
    }
    window.localStorage.clear();
    await instance.logoutPopup();
    window.location.href = '/';
  };

  const updateSubs = async (subId, op) => {
    let newSubs;
    if (op === 'add') {
      newSubs = [...state.user.subs, { feature_id: subId }];
      setState({ ...state, user: { ...state.user, subs: newSubs } });
    } else if (op === 'rem') {
      newSubs = state.user.subs.filter((d) => d.feature_id !== subId);
      setState({ ...state, user: { ...state.user, subs: newSubs } });
    }
  };

  return (
    <div className="App">
      <ToastContainer
        className={'mt-5'}
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className = "app-content-wrapper">
        <AuthenticatedTemplate>
          {state.user.type === undefined && !state.showUserSelectionDialog ? (
            <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <div className="content">
              {!!state.user && !!state.isAuth && <Navbar login={loginUser} logout={logoutUser} isAuth={state.isAuth} user={state.user} />}
              {!!state.user && !!state.isAuth && (
                <GeneralSidebar
                  isAuth={state.isAuth}
                  updateSubs={updateSubs}
                  checkTokenExpiry={checkTokenExpiry}
                  comp_id={state.user.comp_id}
                  user={{ ...state.user }}
                />
              )}
              {state.showUserSelectionDialog && (
                <UserSelectionDialog
                  users={state.users}
                  onSelect={(selectedUser) => handleUserSelection(selectedUser, state.response)}
                  onClose={async() => {
                    setState(prevState => ({ ...prevState, showUserSelectionDialog: false }))
                    instance.logoutPopup();
                  }}
                />
              )}
            </div>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          {!state.redirectedFromEmail ? <Landing appstate={state} isLoading={state.isLoading} loginUser={loginUser} /> : <EmailLanding appstate={state} isLoading={state.isLoading} loginUser={loginUser} />}
        </UnauthenticatedTemplate>
      </div>
    </div>
  );
};

export default App;