import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { createWorkWeek } from '../../Scheduler/WorkWeek'
import { createWorkDay } from '../../Scheduler/WorkDay'
import EditIcon from '@mui/icons-material/Edit';
import GestureIcon from '@mui/icons-material/Gesture';
import ArticleIcon from '@mui/icons-material/Article';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Box, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const localizer = momentLocalizer(moment);

const TaskCalendar = ({ tasks, openingTime, closingTime, selectedDays, onTaskClick, onEditTask, view, onViewChange, onShowSignPopover, onShowCusForms, onShowForm, isSupport, onDeleteTask, onUndoDelete }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const formattedEvents = tasks.map(task => ({
      id: task.tas_id,
      title: task.tas_title,
      start: new Date(task.tas_start_time),
      end: new Date(task.tas_end_time),
      allDay: false,
      task: task
    }));
    setEvents(formattedEvents);
  }, [tasks]);

  const handleDeleteTask = (taskId, taskTitle) => {
    onDeleteTask(taskId, taskTitle);
    setEvents(events.filter(event => event.id !== taskId));
  };

  const handleUndoDelete = (task) => {
    onUndoDelete(task);
    setEvents([...events, {
      id: task.tas_id,
      title: task.tas_title,
      start: new Date(task.tas_start_time),
      end: new Date(task.tas_end_time),
      allDay: false,
      task: task
    }]);
  };

  const EventComponent = ({ event }) => {
    console.log(event);
    return <div style={ view !== 'agenda' ? {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#fff',
        padding: '2px 4px',
        borderRadius: '2px',
        fontSize: '1rem',
    } : {
        display: 'flex',
        flexDirection: 'row',
        lineHeight: '2',
    }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%', flexDirection: 'row', flexWrap: view !== 'month' ? 'wrap' : 'nowrap', overflow: 'hidden' }}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: view !== 'agenda' ? '#fff' : '#000' }}>{event.title}</span>
            <span style={{ display: 'inline-flex', color: view !== 'agenda' ? '#fff' : 'auto' }}>
                {event.task.tas_required_sig === 1 && event.task.tas_is_active === 1 &&
                    <Tooltip title={"Signed on " + moment(event.task.checkoutEmpDate).utc().format("MMM DD, hh:mm A")} >
                        <IconButton onClick={(e) => { e.stopPropagation(); onShowSignPopover(event.task); }} size="small">
                            <GestureIcon sx={{ color: view !== 'agenda' ? '#fff' : 'auto' }} />
                        </IconButton>
                    </Tooltip>
                }
                {event.task.customerForm &&
                    <Tooltip title="Forms" >
                        <IconButton size="small" onClick={(e) => { e.stopPropagation(); onShowCusForms(event.task); }}>
                            <FormatListBulletedIcon sx={{ color: view !== 'agenda' ? '#fff' : 'auto' }} />
                        </IconButton>
                    </Tooltip>
                }
                {event.task.mtInfo && isSupport &&
                    <Tooltip title="MT Task" >
                        <IconButton size="small" onClick={(e) => { e.stopPropagation(); onShowForm(event.task); }}>
                            <ArticleIcon sx={{ color: view !== 'agenda' ? '#fff' : 'auto' }} />
                        </IconButton>
                    </Tooltip>
                }
                {event.task.tas_is_active !== 1 &&
                    <Tooltip title="Edit" >
                        <IconButton size="small" onClick={(e) => { e.stopPropagation(); onEditTask(event.task.tas_id); }}>
                            <EditIcon sx={{ color: view !== 'agenda' ? '#fff' : 'auto' }} />
                        </IconButton>
                    </Tooltip>
                }
                <Tooltip title="Delete" >
                    <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleDeleteTask(event.task.tas_id, event.task.tas_title); }}>
                        <DeleteIcon sx={{ color: view !== 'agenda' ? '#fff' : 'auto' }} />
                    </IconButton>
                </Tooltip>
            </span>
        </Box>
    </div>
};

  return (
    <div style={{ height: 'calc(100vh - 350px)' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100%' }}
        min={openingTime}
        max={closingTime}
        views={{
          month: true,
          week: selectedDays.length > 0 ? createWorkWeek(selectedDays) : true,
          day: selectedDays.length > 0 ? createWorkDay(selectedDays) : true,
          agenda: true
        }}
        components={{
          event: EventComponent
        }}
        onSelectEvent={(event) => onTaskClick(event.task)}
        view={view}
        onView={onViewChange}
      />
    </div>
  );
};

export default TaskCalendar;
