import { useState, useEffect, memo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import lodash from "lodash";

const ControlledAutocomplete = (props) => {
    const [value, setValue] = useState(props.multiple ? [] : null);
    const [error, setError] = useState(false);
    const [read, setRead] = useState(true);
    //const didMount = useRef(false);

    useEffect(() => {
        if(props.options !== undefined) {
            if(props.multiple) {
                // handle multiple selection
                const valueArray = Array.isArray(props.value)
                    ? props.value
                    : props.value ? [props.value] : [];
                const pickedOptions = valueArray.map((val) =>
                    props.options.find((option) => option.value === val) || val
                );
                setValue(pickedOptions);
            } else {
                let pickedOption = null;
                props.options.map((option, index) => {
                    if(option.value === props.value) {
                        pickedOption = option;
                    }
                })
                if(pickedOption !== null) 
                    setValue(pickedOption.label);
                else
                    setValue(props.value);
            }
        }
    }, [props.value]);

    useEffect(() => {
        setError(props.error);
    }, [props.error]);

    useEffect(() => {
        setRead(props.read);
    }, [props.read]);

    useEffect(() => {
        if(read !== true) {
            if(props.disabled === true) {
                setValue("");
                if(props.onChange !== undefined) props.onChange(null);
                if(!isValid(value)) setError(false);
            } else {
                if(!isValid(props.value)) {
                    if(props.dependentValue)
                        props.onChange(props.dependentValue);
                }
                setError(true);
            }
        }
    }, [props.disabled]);

    useEffect(() => {
        if(props.setSectionError !== undefined && props.section !== undefined) 
            error ? props.setSectionError((prev) => { 
                let newPrev = [...prev];
                newPrev[props.section].push('autocomplete');
                return newPrev;
            }) : 
            props.setSectionError((prev) => {
                let newPrev = [...prev];
                const index = newPrev[props.section].indexOf('autocomplete');
                if (index > -1) {
                    newPrev[props.section].splice(index, 1);
                }
                return newPrev;
            });
    }, [error]);

    useEffect(() => {
        if(!props.disabled) 
            setError(!isValid(value));
    }, [value, props.validate]);

    const isValid = (val) => {
        if (props.multiple) {
            return val && val.length > 0;
        }
        return !(val === null || val === "");
    }

    return (
        <>
        {props.value !== undefined ? 
            <Autocomplete 
                error={error && props.validate && !props.disabled}
                inputProps={{...props.inputProps}}
                value={value}
                onChange={(e, newValue) => {
                    if(props.multiple) {
                        props.onChange(Array.isArray(newValue) ? newValue.map((v) => v?.value) : []);
                        setValue(newValue);
                    } else {
                        console.log(newValue);
                        props.onChange(newValue?.value);
                    }
                }}
                options={props.options}
                placeholder={props.placeholder}
                disabled={props.disabled}
                sx={{...props.sx}}
                freeSolo
                renderInput={(params) => <TextField {...params} variant="standard" />}
                multiple={props.multiple}
            >
            </Autocomplete> :
            <></>
        }
        </>
        
    );
}

export default memo(ControlledAutocomplete,
    (prevProps, nextProps) => {
        if(lodash.isEqual(prevProps.sx, nextProps.sx) && lodash.isEqual(prevProps.inputProps, nextProps.inputProps) && lodash.isEqual(prevProps.value, nextProps.value) && lodash.isEqual(prevProps.disabled, nextProps.disabled) 
        && prevProps.onChange == nextProps.onChange && lodash.isEqual(prevProps.placeholder, nextProps.placeholder) && lodash.isEqual(prevProps.options, nextProps.options) && lodash.isEqual(prevProps.multiple, nextProps.multiple)
        && lodash.isEqual(prevProps.error, nextProps.error) && lodash.isEqual(prevProps.validate, nextProps.validate) && lodash.isEqual(prevProps.validQuestions, nextProps.validQuestions) && lodash.isEqual(prevProps.setValidQuestions, nextProps.setValidQuestions) && lodash.isEqual(prevProps.setSectionError, nextProps.setSectionError) && lodash.isEqual(prevProps.section, nextProps.section)) {
            return true;
        } else {
            return false;
        }
    }
);
