import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function DemographicsDashboard(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [iframeUrl, setiframeUrl] = useState('');
    const [isIframeLoading, setIsIframeLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/metabase/demographicsDashboard", {
                params: { companyID: props.user.comp_id },
            })
            .then((response) => {
                setiframeUrl(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    const handleIframeLoad = () => {
        setIsIframeLoading(false);
    };

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
                {/* <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div> */}
            </div>
        )
    } else {
        return (
            <div style={{ width: '75vw' }}>
                {isIframeLoading && (
                    <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50 }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                <iframe
                    src={iframeUrl}
                    style={{ width: '100%', height: '120vh', border: 'none' }}
                    allowTransparency
                    onLoad={handleIframeLoad}
                />
            </div>
        );
    };
};