import React, { useState } from 'react';
import {Form, Button} from 'react-bootstrap';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@mui/styles/withStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './OtherDetails.css'

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
  })(TextField);


const OtherDetails = (props) => {
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState('');
    
    const handleClose = () => {
        props.formChangeHandler({
            target: {
                id: "status",
                value: "Active",
            }
        })
        setOpen(false);
    };

    const handleSubmit = () => {
        if(props.formVals.TransferOutReason) {
            props.formChangeHandler({
                target: {
                    id: "status",
                    value: "Dormant",
                }
            })
        }
        setOpen(false);
    };

    let pManager = !props.empList?[]:props.empList.map((emp,indx) => 
        // <option 
        //     key={indx} 
        //     value={emp.id}>
        //         {(emp.employee_name) ? emp.employee_name : emp.emp_first_name+" "+emp.emp_last_name}
        // </option>
        {
            return ({value : emp.id || emp.emp_id, title: (!!emp.employee_name) ? emp.employee_name : emp.emp_first_name+" "+emp.emp_last_name})
        }
        )
   
    const statusList = props.status.map((elem, index)=>{
        // return(<option key={elem+index}>{elem}</option>)
        return ({value : elem, title: elem})
    })

    const customerTypeList = props.customerType.map((elem, index)=>{
        // return(<option key={elem+index}>{elem}</option>)
        return ({value : elem, title: elem})
    })
    // console.log(pManager)
    
    return(
         <div>
            <Form.Row>
            <div style={{display: 'flex' }}>
                {/* <Form.Group as={Col} controlId="acquired_date">
                <Form.Label>ACQUIRED DATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.acquired_date} type="date" onChange={(event)=> props.formChangeHandler(event)} required readOnly={props.edit}/>
                </Form.Group> */}
                <Form.Row>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        margin="normal"
                        id="acquired_date"
                        label="ACQUIRED DATE *"  
                        className={`mx-2 my-3`}
                        format="MM/DD/YYYY"
                        defaultValue={null}
                        value={dayjs(props.formVals.acquired_date)}
                        onChange={(d) => {props.formChangeHandler({target: {value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss'),id : 'acquired_date'}})}}
                        slotProps={{textField: {variant: 'outlined'}}}
                        />
                </LocalizationProvider>
                {!props.edit ? <Autocomplete
                        id="status"
                        value={statusList.find((type)=> type.title === props.formVals.status) || null}
                        onChange={(e,newValue)=> {
                            props.formChangeHandler({target: {id: 'status', value: newValue.title}})
                        }}
                        options={statusList}
                        getOptionLabel={(option) => option.title}
                        // style={{ width: 450 }}
                        style={{width: 250}}
                        className="mx-2 my-3"
                        renderInput={(params) => <TextField {...params} label="STATUS *"  variant="outlined" />}
                    />
                    :
                    <Autocomplete
                        id="status"
                        value={statusList.find((type)=> type.title === props.formVals.status) || null}
                        onChange={(e,newValue) => {
                            if((newValue.title === 'Dormant') && !props.formVals.TransferOutReason) {
                                props.formChangeHandler({target: {id: 'status', value: newValue.title}})
                                setOpen(true)
                            } else {
                                props.formChangeHandler({target: {id: 'status', value: newValue.title}})
                            }
                        }}
                        options={statusList}
                        getOptionLabel={(option) => option.title}
                        style={{width: 250}}
                        className="mx-2 my-3"
                        // style={{ width: 450 }}
                        renderInput={(params) => <CssTextField {...params} label="STATUS *"  variant="outlined" />}
                    />}
                </Form.Row>
                    <div>
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>Please enter a reason & date below</DialogTitle>
                            <DialogContent>
                            <Autocomplete
                                id="TransferOutReason"
                                options={[
                                    'Individual Passed Away',
                                    'Switched Providers',
                                    'Ended Service',
                                    'Returned to DDD Case Support',
                                    'Moved to MLTSS',
                                    'Moved to another state',
                                    'Disenrolled from DDD'
                                ]}
                                fullWidth
                                onChange={(event, newValue) => {
                                    console.log({target: {value: newValue, id: 'TransferOutReason'}})
                                    props.formChangeHandler({target: {value: newValue, id: 'TransferOutReason'}});
                                    setReason(newValue);
                                }}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label="Reason *" 
                                        variant="standard" 
                                        sx={{ width: '370px' }}
                                    />
                                }
                            />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Grid container className="flex-start" style={{ marginTop: '10px', marginLeft: '-4px' }}>
                                        <DatePicker
                                        margin="normal"
                                        id="TransferOutDate"
                                        label="DATE *"  
                                        format="MM/DD/YYYY"
                                        value={props.formVals.TransferOutDate === "" ? null : dayjs(props.formVals.TransferOutDate)}
                                        onChange={(d) => {props.formChangeHandler({target: {value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss'), id : 'TransferOutDate'}})}}
                                        slotProps={{textField: {variant: 'outlined'}}}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleSubmit} disabled={reason === '' || props.formVals.TransferOutDate == ''}>Submit</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </Form.Row>
            <Form.Row>
                {props.edit && (props.formVals.status === 'Dormant') && <TextField
                    className="w-100"
                    style={{ marginTop: '2vh', marginLeft: '0.25vw', marginBottom: '1vh', maxWidth: '530px' }}
                    label="Reason"
                    value={props.formVals.TransferOutReason}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="TransferOutReason"
                    multiline
                    disabled
                />}
            </Form.Row>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="customer_type">
                    <Form.Label>CUSTOMER TYPE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control value={props.formVals.customer_type} as="select" onChange={(event)=> props.formChangeHandler(event)} required disabled>
                        <option value="">Select Customer Type</option>
                        {customerTypeList}
                    </Form.Control>
                </Form.Group> */}
                <Autocomplete
                    id="customer_type"
                    value={customerTypeList.find((type)=> type.title === props.formVals.customer_type) || null}
                    className={!!props.edit?`mx-1 my-3 w-100`:`mx-1 my-3 w-75`}
                    onChange={(e,newValue)=> {
                        console.log(newValue)
                        props.formChangeHandler({target: {id: 'customer_type', value: newValue.title}})
                    }}
                    options={customerTypeList}
                    getOptionLabel={(option) => option.title}
                    // style={{ width: 450 }}
                    renderInput={(params) => <CssTextField {...params} label="CUSTOMER TYPE *"  variant="outlined" />}
                />
            </Form.Row>
            <Form.Row>
            {/* <Form.Group as={Col} controlId="pManager">
                <Form.Label>PROJECT MANAGER</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.pManager} as="select" onChange={(event)=> props.formChangeHandler(event)} required disabled={props.edit}>
                    {props.edit && pManager}
                    <option value="">Select Manager</option>
                    {pManager}
                </Form.Control>
            </Form.Group> */}
            <Autocomplete
                id="pManager"
                value={pManager.find((type)=> type.value === props.formVals.pManager) || null}
                className={!!props.edit?`mx-1 my-3 w-100`:`mx-1 my-3 w-75`}
                onChange={(e,newValue)=> {
                    let val = pManager.find((man)=> man.title === newValue.title)
                    props.formChangeHandler({target: {id: 'pManager', value: val ? val.value : ''}})
                }}
                options={pManager}
                getOptionLabel={(option) => option.title}
                // style={{ width: 450 }}
                disabled={props.edit}
                renderInput={(params) => <CssTextField {...params} label="RELATED PROJECT MANAGER *" variant="outlined" />}
            />
            </Form.Row>
            <Form.Row>
                {props.isStateNotNJ ?
                <CssTextField
                    style={{width:'48%'}}
                    className="mx-1 my-3"
                    label="MEDICAID ID"
                    value={props.formVals.medicaid_id}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="medicaid_id"
                    type="text"
                    onInput = {(e) =>{
                        //e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,15)
                        e.target.value = (e.target.value).toString().slice(0,15)
                    }}
                /> :
                <CssTextField
                    style={{width:'48%'}}
                    className="mx-1 my-3"
                    label="MEDICAID ID"
                    value={isNaN(props.formVals.medicaid_id)?'':props.formVals.medicaid_id}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="medicaid_id"
                    type="number"
                    onInput = {(e) =>{
                        //e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,15)
                        e.target.value = (e.target.value).toString().slice(0,15)
                    }}
                />}
                <Autocomplete
                    id="medicaid_eligible"
                    style={{width:'48%'}}
                    value={Number(props.formVals.medicaid_eligible) === 1 ? 'Yes' : Number(props.formVals.medicaid_eligible) === 0 ? 'No' : null}
                    className="mx-1 my-3"
                    onChange={(e, v)=> {props.formChangeHandler({target: {id: 'medicaid_eligible', value: v === 'Yes' ? 1 : v === 'No' ? 0 : null }})}}
                    options={['Yes', 'No']}
                    // style={{ width: 450 }}
                    
                    renderInput={(params) => <CssTextField {...params} label="MEDICAID ELIGIBLE" variant="outlined" />}
                />    
            </Form.Row>
            <Form.Row>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        margin="normal"
                        className={props.edit?`mx-2 my-3`:`mx-2 my-3`}
                        id="sign_page_date"
                        label="SIGN PAGE DATE"
                        format="MM/DD/YYYY"
                        value={props.formVals.sign_page_date === "" ? null : dayjs(props.formVals.sign_page_date)}
                        onChange={d=> {props.formChangeHandler({target: {id: 'sign_page_date', value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss')}})}}
                        slotProps={{textField: {variant: 'outlined'}}}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        margin="normal"
                        className={props.edit?`mx-2 my-3`:`mx-2 my-3`}
                        id="last_plan_approval_date"
                        label="LAST PLAN APPROVAL DATE"
                        format="MM/DD/YYYY"
                        value={props.formVals.last_plan_approval_date === "" ? null : dayjs(props.formVals.last_plan_approval_date)}
                        onChange={d=> {props.formChangeHandler({target: {id: 'last_plan_approval_date', value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss')}})}}
                        slotProps={{textField: {variant: 'outlined'}}}
                    />
                </LocalizationProvider>
            </Form.Row>
            <Form.Row>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        margin="normal"
                        className={props.edit?`mx-2 my-3`:`mx-2 my-3`}
                        id="last_assess_date"
                        label="LAST ASSESSMENT DATE"
                        format="MM/DD/YYYY"
                        value={props.formVals.last_assess_date === "" ? null : dayjs(props.formVals.last_assess_date)}
                        onChange={(d)=> {props.formChangeHandler({target: {id: 'last_assess_date', value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss')}})}}
                        slotProps={{textField: {variant: 'outlined'}}}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        margin="normal"
                        className={props.edit?`mx-2 my-3`:`mx-2 my-3`}
                        id="renewal_date"
                        label="RENEWAL DATE"
                        format="MM/DD/YYYY"
                        value={props.formVals.renewal_date === "" ? null : dayjs(props.formVals.renewal_date)}
                        onChange={d=> {props.formChangeHandler({target: {id: 'renewal_date', value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss')}})}}
                        slotProps={{textField: {variant: 'outlined'}}}
                    />
                </LocalizationProvider>
            </Form.Row>
            <Form.Row>
            <CssTextField
                    style={{width:'48%'}}
                    className={`mx-1 my-3`}
                    label="DIAGNOSIS CODE"
                    value={props.formVals.diagnose_code}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="diagnose_code"
                    // disabled={!!this.props.edit?true:false}
                />
                <CssTextField
                    style={{width:'48%'}}
                    className={`mx-1 my-3`}
                    label="DDD TIER"
                    value={props.formVals.DDD_tier}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="DDD_tier"
                    onInput = {(e) =>{
                        e.target.value = e.target.value.toString().slice(0,6)
                    }}
                    // disabled={!!this.props.edit?true:false}
                />
            </Form.Row>
            <Form.Row>
                <CssTextField
                    style={{width:'48%'}}
                    className="mx-1 my-3"
                    label="WAIVER PROGRAM"
                    value={props.formVals.waiver_program}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="waiver_program"
                    // disabled={!!this.props.edit?true:false}
                />
                <Autocomplete
                    id="func_criteria_met"
                    style={{width:'48%'}}
                    value={Number(props.formVals.func_criteria_met) === 1 ? 'Yes' : Number(props.formVals.func_criteria_met) === 0 ? 'No' : null}
                    className="mx-1 my-3"
                    onChange={(e, v)=> {props.formChangeHandler({target: {id: 'func_criteria_met', value: v === 'Yes' ? 1 : v === 'No' ? 0 : null }})}}
                    options={['Yes', 'No']}
                    // style={{ width: 450 }}
                    renderInput={(params) => <CssTextField {...params} label="FUNCTIONAL CRITERIA MET" variant="outlined" />}
                />
            </Form.Row>
            <Form.Row>                
                <CssTextField
                    style={{width:'48%'}}
                    className="mx-1 my-3"
                    label="NJCATSelfcare"
                    value={props.formVals.NJCATSelfcare}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="NJCATSelfcare"
                    type="number"
                    // disabled={!!this.props.edit?true:false}
                />
                <CssTextField
                    style={{width:'48%'}}
                    className="mx-1 my-3"
                    label="NJCATBehavioral"
                    value={props.formVals.NJCATBehavioral}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="NJCATBehavioral"
                    type="number"
                    // disabled={!!this.props.edit?true:false}
                />
            </Form.Row>
            <Form.Row>                
                <CssTextField
                    style={{width:'48%'}}
                    className="mx-1 my-3"
                    label="NJCATMedical"
                    value={props.formVals.NJCATMedical}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="NJCATMedical"
                    type="number"
                    // disabled={!!this.props.edit?true:false}
                />
                <CssTextField
                    style={{width:'48%'}}
                    className="mx-1 my-3"
                    label="NJCATFCA"
                    value={props.formVals.NJCATFCA}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="NJCATFCA"
                    // disabled={!!this.props.edit?true:false}
                />
            </Form.Row>
            <Form.Row>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        margin="normal"
                        className={props.edit?`mx-2 my-3`:`mx-2 my-3`}
                        id="MedicaidEndDate"
                        label="Medicaid End Date"
                        format="MM/DD/YYYY"
                        value={props.formVals.MedicaidEndDate === "" ? null : dayjs(props.formVals.MedicaidEndDate)}
                        onChange={d=> {props.formChangeHandler({target: {id: 'MedicaidEndDate', value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss')}})}}
                        slotProps={{textField: {variant: 'outlined'}}}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        margin="normal"
                        className={props.edit?`mx-2 my-3`:`mx-2 my-3`}
                        id="AgencyAssignDate"
                        label="Agency Assign Date"
                        format="MM/DD/YYYY"
                        value={props.formVals.AgencyAssignDate === "" ? null : dayjs(props.formVals.AgencyAssignDate)}
                        onChange={d=> {props.formChangeHandler({target: {id: 'AgencyAssignDate', value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss')}}); console.log(dayjs(props.formVals.AgencyAssignDate).format("MM/DD/YYYY"))}}
                        slotProps={{textField: {variant: 'outlined'}}}
                    />
                </LocalizationProvider>
            </Form.Row>
            <Form.Row>                
                <CssTextField
                    style={{width:'48%'}}
                    className="mx-1 my-3"
                    label="County"
                    value={props.formVals.County}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="County"
                    // disabled={!!this.props.edit?true:false}
                />
                <CssTextField
                    style={{width:'48%'}}
                    className="mx-1 my-3"
                    label="Race"
                    value={props.formVals.Race}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="Race"
                    inputProps={{ maxLength: 45 }}
                    // disabled={!!this.props.edit?true:false}
                />
                <CssTextField
                    style={{width:'48%'}}
                    className="mx-1 my-3"
                    label="Ethnicity"
                    value={props.formVals.Ethnicity}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="Ethnicity"
                    // disabled={!!this.props.edit?true:false}
                />
            </Form.Row>
            {!props.edit && <Button className={'w-75 ml-2'} variant="primary" block type="submit">
                Create Customer
            </Button>}
            {props.edit && <Button className={'w-100'} variant="primary" block type="button" onClick={() => props.onContinue("customer_tasks")}>
                Continue
            </Button>}
         </div>
    )
};

export default OtherDetails;