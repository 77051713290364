import React, { useEffect, useState } from "react";
import { Button, Modal } from 'react-bootstrap'
import { APIURL } from "../../config";
import axios from "axios";
import moment, { weekdays } from "moment";
import TimeSheetConfirmModal from "./TimeSheetConfirmModal";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import CircularProgress from '@mui/material/CircularProgress';
import TimesheetForm from "../forms/TimesheetForm/TimesheetForm";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { IconButton } from '@mui/material';
import ViewFormModal from "../forms/JsonForm/ViewFormModal";

export default function WeeklyTimesheetAlt(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(props.openByDefault ? true : false);
  const [rows, setRows] = React.useState([]);
  const [totalHours, setTotalHours] = React.useState(-1);
  const [showTimesheetModal, setShowTimesheetModal] = useState(false);
  const [currentlEditedTimesheetTaskId, setCurrentEditedTimesheetTaskId] = useState(null);
  const [currentTask, setCurrentTask] = useState({});
  const [showTaskForms, setshowTaskForms] = useState(false);


  const columns = [
    { field: "id", headerName: "Task ID", flex: 1, hide:true },
    { field: "date", headerName: "Date", flex: 1 },
    { field: "total_hours", headerName: "Hours", flex: 0.5 },
    { field: 'projectName', headerName: 'Project Name', flex: 1},
    { field: 'taskName', headerName: 'Task Name', flex: 1 },
    { field: 'taskType', headerName: 'Task Type', flex: 1 },
    { field: "notes", headerName: "Task Comments", flex: 1, hide:true },
    { field: "start_time", headerName: "Scheduled Start Time", flex: 1, hide:true },
    { field: "end_time", headerName: "Scheduled End Time", flex: 1, hide:true },
    { field: "a_start_time", headerName: "Start Time", flex: 1 },
    { field: "a_end_time", headerName: "End Time", flex: 1 },
    {
      field: "actions",
      headerName: " ",
      flex: 0.7,
      headerClassName: "timesheets-summarytable-columnheader",
      renderCell: (params) => {
        if(props.timeSheetData.time_sheet_status == 'ARCHIVED' || props.timeSheetData.time_sheet_status == 'APPROVED'){
          return (<></>);
        }
        return (
          <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            { params.row.task_form && params.row.task_form.length > 0 &&
              <IconButton
                onClick={() => {setCurrentTask(params.row); setshowTaskForms(true);}}
                size="small"
              >
                <FormatListBulletedIcon/>
              </IconButton>
            }
            <IconButton
              onClick={() => {
                setCurrentEditedTimesheetTaskId(params.row.id);
                setShowTimesheetModal(true)
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={async() => {
                if (["ACTIVE","OPEN","SUBMITTED"].includes(props.timeSheetData.time_sheet_status)){
                    let data = { id: params.row.id, empId: props.user.emp_id, empName: props.user.emp_name, companyId: props.comp_id, taskName: params.row.taskName, projectId: params.row.pro_id, timesheetEntry: true }
                    
                    let deleteTaskResponse = await axios.delete(
                        APIURL + '/task',
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*',
                                "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
                                "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
                                "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
                                Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
                            },
                            data,
                        }
                    );

                    if (deleteTaskResponse.status === 200) {
                        alert('Task Deleted Successfully.')
                    } else {
                        alert('An error occurred while deleting the timesheet.')
                    }
                }
              }}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
        return <span></span>
      },
    },
  ];

  useEffect(() => {
    const fetchTaskData = async() => {
      const randomInt = Math.floor(Math.random() * 1000);
      if(props.openByDefault){
        setIsLoading(true);
        await fetchTimesheetTaskData();
        setIsLoading(false);
        setOpen(props.openByDefault);
      }

    };
    fetchTaskData();
  }, [props.timeSheetData]);

  useEffect(() => {
    setRows([])
    setTotalHours(-1)
    setOpen(props.openByDefault ? open : false)
  }, [props.currentEmployee]);

  const toggle = async() => {
    setOpen(!open);
    if(!open){
      setIsLoading(true);
      await fetchTimesheetTaskData();
      setIsLoading(false);
    }
  }

  const fetchTimesheetTaskData = async() => {
    await axios.get(`${APIURL}/time_sheet/v2/tasks`, {
        params: {
            emp_id: props.timeSheetData.emp_id,
            pay_period_id: props.timeSheetData.pay_period_id[0]
        }
    }).then(response => {
        const data = response.data.map((row) => {
            return {
                id: row.tas_id,
                date: moment(row.empLocDate).format('DD MMM YYYY'),
                total_hours: moment.duration(moment(row.checkoutEmpDate).diff(moment(row.empLocDate))).asHours(),
                projectName: row.pro_title,
                taskName: row.tas_title,
                taskType: row.tas_type, 
                start_time: moment(row.tas_start_time.replace(/Z/g, "")).format('MMM DD [at] h:mma'),
                end_time: moment(row.tas_end_time.replace(/Z/g, "")).format('MMM DD [at] h:mma'),
                a_start_time: moment(row.empLocDate.replace(/Z/g, "")).format('MMM DD [at] h:mma'),
                a_end_time: moment(row.checkoutEmpDate.replace(/Z/g, "")).format('MMM DD [at] h:mma'),
                notes: row.tas_notes,
                task_form: row.task_form,
                cus_id: row.cus_id,
                pro_id: row.tas_pro_id
            };
        });
        setRows(data);
        const totalHours = data.reduce((sum, row) => sum + row.total_hours, 0);
        setTotalHours(totalHours.toFixed(2));
    });
  }

  const toggleLock = async () => {
    if (props.timeSheetData.time_sheet_status === "APPROVED") {
      let empTimeSheetUpdate = await axios.put(APIURL + "/time_sheet", {
        time_sheet_id: props.timeSheetData.time_sheet_id,
        company_id: props.timeSheetData.company_id,
        emp_id: props.timeSheetData.emp_id,
        pay_period_id: props.timeSheetData.pay_period_id[0],
        time_sheet_status: "ARCHIVED",
        submitted_on: props.timeSheetData.submitted_on,
        approved_on: props.timeSheetData.approved_on,
        approved_by: props.timeSheetData.approved_by,
      });
      if (empTimeSheetUpdate.data.code === 200){
        let tempCurrentEmployee = props.currentEmployee;
        props.setCurrentEmployee(null);
        props.setCurrentEmployee(tempCurrentEmployee)
      }
    } else if (props.timeSheetData.time_sheet_status === "ARCHIVED") {
      let empTimeSheetUpdate = await axios.put(APIURL + "/time_sheet", {
        time_sheet_id: props.timeSheetData.time_sheet_id,
        company_id: props.timeSheetData.company_id,
        emp_id: props.timeSheetData.emp_id,
        pay_period_id: props.timeSheetData.pay_period_id[0],
        time_sheet_status: "APPROVED",
        submitted_on: "",
        approved_on: "",
        approved_by: null,
      });
      if (empTimeSheetUpdate.data.code === 200){
        let tempCurrentEmployee = props.currentEmployee;
        props.setCurrentEmployee(null);
        props.setCurrentEmployee(tempCurrentEmployee)
      }
    }
  };

  const toggleApprove = async () => {
    if (props.timeSheetData.time_sheet_status === "SUBMITTED") {
      let empTimeSheetUpdate = await axios.put(APIURL + "/time_sheet", {
        time_sheet_id: props.timeSheetData.time_sheet_id,
        company_id: props.timeSheetData.company_id,
        emp_id: props.timeSheetData.emp_id,
        pay_period_id: props.timeSheetData.pay_period_id[0],
        time_sheet_status: "APPROVED",
        submitted_on: props.timeSheetData.submitted_on,
        approved_on: new Date().toISOString().slice(0, 10),
        approved_by: props.user.emp_id,
      });
      if (empTimeSheetUpdate.data.code === 200){
        let tempCurrentEmployee = props.currentEmployee;
        props.setCurrentEmployee(null);
        props.setCurrentEmployee(tempCurrentEmployee)
      }
    } else if (props.timeSheetData.time_sheet_status === "APPROVED") {
      console.log("GOT HERE")
      let empTimeSheetUpdate = await axios.put(APIURL + "/time_sheet", {
        time_sheet_id: props.timeSheetData.time_sheet_id,
        company_id: props.timeSheetData.company_id,
        emp_id: props.timeSheetData.emp_id,
        pay_period_id: props.timeSheetData.pay_period_id[0],
        time_sheet_status: "SUBMITTED",
        submitted_on: props.timeSheetData.submitted_on,
        approved_on: "",
        approved_by: null,
      });
      if (empTimeSheetUpdate.data.code === 200){
        let tempCurrentEmployee = props.currentEmployee;
        props.setCurrentEmployee(null);
        props.setCurrentEmployee(tempCurrentEmployee)
      }
    }
  };

  const toggleSubmit = async () => {
    if (["ACTIVE", "OPEN"].includes(props.timeSheetData.time_sheet_status)){
      let empManager = null;
      try {
        empManager = (
          await axios.get(APIURL + "/employees", {
            params: {
              com_id: props.timeSheetData.company_id,
              emp_id: props.timeSheetData.emp_id,
            },
          })
        ).data[0].emp_manager_id;
      } catch (err) {
        console.log(`ERROR: Could not find Manager`);
      }
      console.log(empManager);
      let empTimeSheetUpdate = await axios.put(APIURL + "/time_sheet", {
        emp_manager: empManager,
        time_sheet_id: props.timeSheetData.time_sheet_id,
        company_id: props.timeSheetData.company_id,
        emp_id: props.timeSheetData.emp_id,
        pay_period_id: props.timeSheetData.pay_period_id[0],
        time_sheet_status: "SUBMITTED",
        submitted_on: moment().format("YYYY-MM-DDT00:00:00"),
        approved_on: "",
        approved_by: null,
      });
      if (empTimeSheetUpdate.data.code === 200){
        let tempCurrentEmployee = props.currentEmployee;
        props.setCurrentEmployee(null);
        props.setCurrentEmployee(tempCurrentEmployee)
      }
    } else if (props.timeSheetData.time_sheet_status === "SUBMITTED") {
      let empManager = null;
      try {
        empManager = (
          await axios.get(APIURL + "/employees", {
            params: {
              com_id: props.timeSheetData.company_id,
              emp_id: props.timeSheetData.emp_id,
            },
          })
        ).data[0].emp_manager_id;
      } catch (err) {
        console.log(`ERROR: Could not find Manager: ${err}`);
      }
      console.log(empManager);
      let empTimeSheetUpdate = await axios.put(APIURL + "/time_sheet", {
        emp_manager: empManager,
        time_sheet_id: props.timeSheetData.time_sheet_id,
        company_id: props.timeSheetData.company_id,
        emp_id: props.timeSheetData.emp_id,
        pay_period_id: props.timeSheetData.pay_period_id[0],
        time_sheet_status: "ACTIVE",
        submitted_on: moment().format("YYYY-MM-DDT00:00:00"),
        approved_on: "",
        approved_by: null,
      });
      if (empTimeSheetUpdate.data.code === 200){
        let tempCurrentEmployee = props.currentEmployee;
        props.setCurrentEmployee(null);
        props.setCurrentEmployee(tempCurrentEmployee)
      }
    }
  }

  const getStatusTitle = (status) => {
    if (status === "OPEN")
      return (
        <span style={{ float: "right", marginRight: "2%", color: "blue" }}>OPEN</span>
      );
    if (status === "ACTIVE")
      return (
        <span style={{ float: "right", marginRight: "2%", color: "blue" }}>
          ACTIVE
        </span>
      );
    else if (status === "SUBMITTED")
      return (
        <span style={{ float: "right", marginRight: "2%", color: "orange" }}>
          SUBMITTED
        </span>
      );
    else if (status === "APPROVED")
      return (
        <span style={{ float: "right", marginRight: "2%", color: "green" }}>
          APPROVED
        </span>
      );
    else if (status === "ARCHIVED")
      return (
        <span style={{ float: "right", marginRight: "2%", color: "gray" }}>
          ARCHIVED
        </span>
      );
  }

  const getSubmitButton = () => {
    if (["ACTIVE", "OPEN"].includes(props.timeSheetData.time_sheet_status)) {
      return (
        <TimeSheetConfirmModal
          type={"SUBMIT"}
          status={props.timeSheetData.time_sheet_status}
          onClick={toggleSubmit.bind(this)}
        >
          <button
            style={{
              marginRight: "10px",
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
            }}
            // onClick={submitTimeSheet.bind(this)}
          >
            Submit Timesheet
          </button>
        </TimeSheetConfirmModal>
      );
    } else if (props.timeSheetData.time_sheet_status === 'SUBMITTED' && /(admin|manager)/i.test(props.user.type)){
      return (
        <TimeSheetConfirmModal
          type={"SUBMIT"}
          status={props.timeSheetData.time_sheet_status}
          onClick={toggleSubmit.bind(this)}
        >
          <button
            style={{
              marginRight: "10px",
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
            }}
            // onClick={submitTimeSheet.bind(this)}
          >
            Reject Timesheet
          </button>
        </TimeSheetConfirmModal>
      );
    }
    return;
  }

  const getLockButton = () => {
    if (props.timeSheetData.time_sheet_status === "APPROVED")
      return (
        <TimeSheetConfirmModal
          type={"LOCK"}
          onClick={toggleLock.bind(this)}
        >
          <button
            style={{
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
            }}
          >
            Lock
          </button>
        </TimeSheetConfirmModal>
      );
    else if (props.timeSheetData.time_sheet_status === "ARCHIVED")
      return (
        <TimeSheetConfirmModal
          type={"UNLOCK"}
          onClick={toggleLock.bind(this)}
        >
          <button
            style={{
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
            }}
          >
            Unlock
          </button>
        </TimeSheetConfirmModal>
      );
    else
      return (
        <TimeSheetConfirmModal
          disabled
          type={"LOCK"}
          onClick={toggleLock.bind(this)}
        >
          <button
            disabled
            style={{
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
              backgroundColor: "rgba(170, 170, 170, 1)",
            }}
          >
            Lock
          </button>{" "}
        </TimeSheetConfirmModal>
      );
  }

  const getApproveButton = () => {
    if (props.timeSheetData.time_sheet_status === "ACTIVE")
      return (
        <TimeSheetConfirmModal
          disabled
          type={"APPROVE"}
          onClick={toggleLock.bind(this)}
        >
          <button
            disabled
            style={{
              marginRight: "10px",
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
              backgroundColor: "rgba(170, 170, 170, 1)",
            }}
            // onClick={this.toggleApprove.bind(this)}
          >
            Approve
          </button>
        </TimeSheetConfirmModal>
      );
    else if (props.timeSheetData.time_sheet_status === "ARCHIVED")
      return (
        <TimeSheetConfirmModal
          disabled
          type={"APPROVE"}
          onClick={toggleLock.bind(this)}
        >
          <button
            disabled
            style={{
              marginRight: "10px",
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
              backgroundColor: "rgba(170, 170, 170, 1)",
            }}
          >
            Approve
          </button>
        </TimeSheetConfirmModal>
      );
    else if (props.timeSheetData.time_sheet_status === "SUBMITTED")
      return (
        <TimeSheetConfirmModal
          disabled={["Fieldworker", "Support Coordinator"].includes(
            props.user.type
          )}
          type={"APPROVE"}
          onClick={toggleApprove.bind(this)}
        >
          <button
            style={{
              marginRight: "10px",
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
              backgroundColor: ["Fieldworker", "Support Coordinator"].includes(
                props.user.type
              )
                ? "rgba(170, 170, 170, 1)"
                : "",
            }}
            disabled={["Fieldworker", "Support Coordinator"].includes(
              props.user.type
            )}
            // onClick={this.toggleApprove.bind(this)}
          >
            Approve
          </button>
        </TimeSheetConfirmModal>
      );
    else if (props.timeSheetData.time_sheet_status === "APPROVED")
      return (
        <TimeSheetConfirmModal
          disabled={["Fieldworker", "Support Coordinator"].includes(
            props.user.type
          )}
          type={"UNAPPROVE"}
          onClick={toggleApprove}
        >
          <button
            style={{
              marginRight: "10px",
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
              backgroundColor: ["Fieldworker", "Support Coordinator"].includes(
                props.user.type
              )
                ? "rgba(170, 170, 170, 1)"
                : "",
            }}
            disabled={["Fieldworker", "Support Coordinator"].includes(
              props.user.type
            )}
            // onClick={this.toggleApprove.bind(this)}
          >
            Unapprove
          </button>
        </TimeSheetConfirmModal>
      );
  }

  return (
    (<div className="weeklytimesheet">
      <button
        className="weeklytimesheetbutton"
        onClick={async() => await toggle()}
      >
        <p style={{ float: "left", minWidth: 500 }}>
          {
          `${moment(props.timeSheetData.start_date.replace(/Z/g, "")).format("dddd[ ]MMMM[ ]DD[ ]")} - ${moment(props.timeSheetData.end_date.replace(/Z/g, "")).format("dddd[ ]MMMM[ ]DD[ ]YYYY")}`
          }
        </p>
        <p style={{ float: "left", marginLeft: "3%" }}>
          {isLoading ? 'Loading Data...' : (totalHours !== -1 && `Total Hours: ${totalHours} hours`)}
        </p>
        <i
          style={{ float: "right" }}
          class="fa fa-chevron-down"
          aria-hidden="true"
        ></i>
        {getStatusTitle(props.timeSheetData.time_sheet_status)}
        <p style={{ float: "right" }}>Approval Status:&nbsp;</p>
      </button>
      <div
        className={
          "week-approval-dropdown week-approval-dropdown-collapse" +
          (open ? " in" : "")
        }
      >
        {isLoading ? <CircularProgress style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} /> :
        <div style={{}}>
          <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={30}
          initialState={{ density: 'comfortable'}}
          pageSize={9}
          showToolbar
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar, color: "#3fadf6" }}
          />

          <div id='timesheet-add-new-entry-button-div'>
            {!['ARCHIVED','APPROVED'].includes(props.timeSheetData.time_sheet_status) ? (
              <div style={{ textAlign: 'right' }}>
                <Button 
                  className="FW-Button" 
                  onClick={() => setShowTimesheetModal(true)}
                  style={{ width: '150px', padding: '2px', marginTop: '5px' }}
                >
                  Add New Entry
                </Button>
              </div> 
            ) : <></>}
          </div>

          <div className="lockapprive-div" style={{ minHeight: "30px" }}>
            {getLockButton()}
            {getApproveButton()}
            {getSubmitButton()}
            <p style={{ marginTop: "5px", fontSize: "14px" }}>
              Submitted On: {props.timeSheetData.submitted_on && moment(props.timeSheetData.submitted_on.replace(/Z/g, "")) > moment('1900-12-31') ? moment(props.timeSheetData.submitted_on.replace(/Z/g, "")).format("MMM DD, YYYY") : "N/A"}
            </p>
          </div>
        </div>
        }
        
      </div>
      <ViewFormModal
        forms={currentTask.task_form ? currentTask.task_form : []}
        cus_id={currentTask? currentTask.cus_id : ''} 
        task_id={currentTask ? currentTask.id : ''}
        user_id={props.user.emp_id}
        read={false}
        show={showTaskForms}
        hide={() => setshowTaskForms(false)}
      />
      <Modal
          show={showTimesheetModal}
          onHide={() => {
            setShowTimesheetModal(false)
            setCurrentEditedTimesheetTaskId(null)
          }}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
      >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
              <TimesheetForm user={props.user} employee_id={props.currentEmployee.emp_id} task_id={currentlEditedTimesheetTaskId} onSubmit={() => {
                  setCurrentEditedTimesheetTaskId(null);
                  setShowTimesheetModal(false);

                  //refresh the the employee timesheet data page in the parent component
                  props.setCurrentEmployeeRefresh(true);
              } }></TimesheetForm>
          </Modal.Body>
      </Modal>
    </div>)
  );
}