import React, { useState, useEffect } from 'react';
import {
    Box,
    FormControlLabel,
    Checkbox,
    TextField,
    Typography,
    Stack,
    Grid,
    FormGroup,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { debounce } from 'lodash';
import SaveButton from '../Components/SaveButton';
import axios from 'axios';
import { APIURL } from '../../../config';
import moment from 'moment';

const Scheduler = (props) => {
    const [selectedDays, setSelectedDays] = useState({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
    });
    const [openingTime, setOpeningTime] = useState(null);
    const [closingTime, setClosingTime] = useState(null);
    const [originalSchedulerHours, setOriginalSchedulerHours] = useState(null);

    const handleDayChange = (event) => {
        setSelectedDays({ ...selectedDays, [event.target.name]: event.target.checked });
    };

    // Debounced API call function
    const saveSchedulerHours = debounce(async () => {
        const daysArray = Object.keys(selectedDays).filter(day => selectedDays[day]);
        const data = {
            days: daysArray,
            openingTime: moment(openingTime).local().format('YYYY-MM-DDTHH:mm:ss'),
            closingTime: moment(closingTime).local().format('YYYY-MM-DDTHH:mm:ss'),
        };

        try {
            let settingsData = (await axios.get(APIURL+"/config/settings", {params: {comp_id: props.comp_id, feature_id: 80}})).data
            if(settingsData.length > 0){
                let settingsPayload = {
                    settings_id: settingsData[0].settings_id, feature_value: JSON.stringify(data), feature_id: 80,
                }
                let result = await axios.put(APIURL+`/config/settings`, {...settingsPayload})
            } else {
                let settingsPayload = {
                    feature_value: JSON.stringify(data),
                    feature_id: 80,
                    company_id: props.comp_id,
                    added_by: props.user_id
                }
                let result = await axios.post(APIURL+`/config/settings`, {...settingsPayload})
            }
            setOriginalSchedulerHours(data);
        } catch (error) {
            console.error('Failed to save scheduler hours:', error);
        }
    }, 500);

    useEffect(() => {
        // Fetch scheduler hours data
        setOpeningTime(props.schedulerHours?.openingTime || null);
        setClosingTime(props.schedulerHours?.closingTime || null);
        setSelectedDays({
            Monday: props.schedulerHours?.days?.includes('Monday') || false,
            Tuesday: props.schedulerHours?.days?.includes('Tuesday') || false,
            Wednesday: props.schedulerHours?.days?.includes('Wednesday') || false,
            Thursday: props.schedulerHours?.days?.includes('Thursday') || false,
            Friday: props.schedulerHours?.days?.includes('Friday') || false,
            Saturday: props.schedulerHours?.days?.includes('Saturday') || false,
            Sunday: props.schedulerHours?.days?.includes('Sunday') || false,
        });
        setOriginalSchedulerHours(props.schedulerHours);
    }, [props.schedulerHours]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={props.leftColumnSize}>
                        <Stack spacing={1}>
                            <Typography variant="h5">Scheduler</Typography>
                            <Typography variant="body1" sx={{ color: 'gray' }}>
                                Set your operating hours
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={props.rightColumnSize}>
                        <Stack spacing={2}>
                            <Typography variant="h6" sx={{ mb: 1 }}>
                                Select Operating Days
                            </Typography>
                            <FormGroup row sx={{ mb: 2 }}>
                                {Object.keys(selectedDays).map(day => (
                                    <FormControlLabel
                                        key={day}
                                        control={<Checkbox checked={selectedDays[day]} onChange={handleDayChange} name={day} />}
                                        label={day}
                                    />
                                ))}
                            </FormGroup>
                            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ mb: 2 }}>
                                <TimePicker
                                    label="Opening Time"
                                    value={moment(openingTime).isValid() ? new Date(moment(openingTime)) : null}
                                    onChange={setOpeningTime}
                                    slotProps={{textField: {variant: 'outlined'}}}
                                />
                                <TimePicker
                                    label="Closing Time"
                                    value={moment(closingTime).isValid() ? new Date(moment(closingTime)) : null}
                                    onChange={setClosingTime}
                                    slotProps={{textField: {variant: 'outlined'}}}
                                />
                            </Stack>
                            <SaveButton
                                onClick={saveSchedulerHours}
                                disabled={
                                    JSON.stringify(originalSchedulerHours) === JSON.stringify({
                                        days: Object.keys(selectedDays).filter(day => selectedDays[day]),
                                        openingTime,
                                        closingTime,
                                    })
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
};

export default Scheduler;
