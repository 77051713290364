import React, { useState, useEffect } from "react"
import { CSVReader } from 'react-papaparse';
import { Button } from 'react-bootstrap';
import { APIURL } from '../../../config';
import { useUtils } from '../../../utils/utilities';
import axios from 'axios';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Icon from '@mui/material/Icon';
import moment from 'moment'
import './BulkUpload.css'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

const columns = [
    { 
        field: 'id',
        headerName: 'ID',
        flex: 0.25,
        hide: true,
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        editable: false,
    },
    {
        field: 'created',
        headerName: 'Created',
        flex: 0.33,
        editable: false,
    },
    {
        field: 'updated',
        headerName: 'Updated',
        flex: 0.33,
        editable: false,
    },
    {
        field: 'error',
        headerName: 'Error',
        flex: 0.33,
        editable: false,
    },
    {
        field: 'error_details',
        headerName: 'Error Details',
        flex: 1.5,
        editable: false,
    },
];

const useStyles = makeStyles((theme) => ({
    myClass: {
      color: "#007bff !important",
      "&:hover": {
        color:'#007bee !important',
        cursor: 'pointer'
      },
    },
  }));


export default function IRecordSync(props){

    const [CSVData, setCSVData] = useState();
    const [file, setFile] = useState();
    const [option, setOption] = useState('1');
    const [showImportButton, setShowImportButton] = useState(false);

    const [showCustomerTable, setShowCustomerTable] = useState(false)
    const [showEmployeeTable, setShowEmployeeTable] = useState(false)
    const [showIRecordTable, setShowIRecordTable] = useState(false)
    const [employeeRows, setEmployeeRows] = useState([]);
    const [customerRows, setCustomerRows] = useState([]);
    const [iRecordRows, setiRecordRows] = useState([]);
    const [responseMessage, setResponseMessage] = useState(null)
    const [alertText, setAlertText] = useState('')
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false);
    const [loggedToSegment, setLoggedToSegment] = useState(false);
    const [syncResults, setSyncResults] = useState(null)

    const { openLinkInTab } = useUtils();
    const classes = useStyles();
    const logToSegment = () => {
        if(loggedToSegment) {
            return;
        }
        let page = String(window.location.href).split("/").pop()
        console.log(page,window.location.href)
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page)
        analytics.page(page + " : DDD Sync",{
            title:page + " : DDD Sync",
            path:page + " : DDD Sync",
        }, {
          userId:String(props.user.emp_id),
        })
        console.log("logged to segment successfully")
        setLoggedToSegment(true);
      }

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            
        }
        logToSegment();
        fetchData();
        return () => { mounted = false };
        // eslint-disable-next-line
    }, []);

    function onSelected(event){
        setOption(event.target.value)
    }

    function handleOnDrop(data, file) {
        setCSVData(data)
        setFile(file)
        setShowImportButton(true)
    }

    function handleChange(data) {
        setCSVData(data)
    }

    function handleOnError(err, file, inputElem, reason) {
        console.log(err)
    }

    function handleOnRemoveFile() {
        setCSVData(null)
        setFile(null)
        setShowImportButton(false)
    }

    const onAlertClose = () => {
        setAlertText('')
        setAlertSeverity('info')
    }

    async function handleSync(){
        var formDataPayload = new FormData()
        formDataPayload.append("filecontent", file)
        setLoading(true)
        let syncResult = await axios.post(APIURL + `/bulkupload/sync?company_id=` + props.comp_id, formDataPayload)
        console.dir(syncResult.data.results)

        if(syncResult.status == 200){
            let sessionID = window.localStorage.getItem("Fw4_access_Token");
            var sha1 = require("sha1");
            var hash = sha1(sessionID);
            var log_data = {
                email: props.user.email,
                actionType: "Data Import",
                actionSubType: `DDD Sync bulk import`,
                sessionID: hash,
            };
            let msg = await axios.post(APIURL + "/activity_log", log_data);
            setAlertText('IRecord Sync process completed')
            setAlertSeverity('success')
        } else if(syncResult.status > 226){
            setAlertText(`An error occured when attempting the IRecord Sync process (${syncResult.status})`)
            setAlertSeverity('error')
        } else {
            setAlertText('An error occured when attempting the IRecord Sync process')
            setAlertSeverity('error')
        }

        let newRowValues = [0,0,0,0,0,0,0,0,0]
        let newRows = []
        const syncResults = syncResult.data.results;
        
        for(let i = 0; i<syncResults.length; i++){
            for(let j = 0; j < syncResults[i].length; j++){
                if(syncResults[i][j].type == 'employee'){
                    if(syncResults[i][j].status == 'new'){
                        newRowValues[0] += 1
                    } else if(syncResults[i][j].status == 'update'){
                        newRowValues[1] += 1
                    } else if(syncResults[i][j].status == 'error'){
                        newRowValues[2] += 1
                    }
                } else if(syncResults[i][j].type == 'customer'){
                    if(syncResults[i][j].status == 'new'){
                        newRowValues[3] += 1
                    } else if(syncResults[i][j].status == 'update'){
                        newRowValues[4] += 1
                    } else if(syncResults[i][j].status == 'error'){
                        newRowValues[5] += 1
                    }
                } else if(syncResults[i][j].type == 'project'){
                    if(syncResults[i][j].status == 'new'){
                        newRowValues[6] += 1
                    } else if(syncResults[i][j].status == 'update'){
                        newRowValues[7] += 1
                    } else if(syncResults[i][j].status == 'error'){
                        newRowValues[8] += 1
                    }
                }
            }   
        }

        console.log(newRowValues)

        newRows.push({path: ['employee'], id: -2, description: '', created: newRowValues[0], updated: newRowValues[1], error: newRowValues[2]})
        newRows.push({path: ['customer'], id: -1, description: '', created: newRowValues[3], updated: newRowValues[4], error: newRowValues[5]})
        newRows.push({path: ['project'], id: 0, description: '', created: newRowValues[6], updated: newRowValues[7], error: newRowValues[8]})
        
        let nextId=1;
        let nextRowNumber = 1;
        for(let i=0; i<syncResults.length; i++){
            for(let j=0; j<syncResults[i].length; j++){
                let data={
                    path: [syncResults[i][j].type, nextRowNumber],
                    id: nextId,
                    description: syncResults[i][j].description,
                    created: syncResults[i][j].status=='new' ? 1 : 0,
                    updated: syncResults[i][j].status=='update' ? 1 : 0,
                    error: syncResults[i][j].status=='error' ? 1 : 0
                }
                nextId++;
                newRows.push(data)
            }
            nextRowNumber++;
        }

        setRows(newRows)
        setSyncResults(syncResults)
        setLoading(false)
    }

    return (
        (<div style={{width: '100%'}}>
            <h4> DDD Sync </h4>
            <div style={{ fontFamily: 'Lato', backgroundColor: 'white', marginTop: '15px', marginBottom: '15px', border: '1px solid rgb(204, 204, 204)', borderRadius: '15px', padding: '10px 10px 10px 15px' }}>
                <div>
                    <p style={{ display: 'inline', marginRight: '10px' }}>~ DDD synch functionality can be used to bring the latest information from DDD iRecords system and update Fieldworker.</p>
                </div>
                <div>
                    <p style={{ display: 'inline', marginRight: '10px' }}>~ This will ensure that Fieldworker always has the most up to date information.</p>
                </div>
                <div>
                    <p style={{ display: 'inline', marginRight: '10px' }}>~ The data for this synch is generated using the Participating search option in DDD.</p>
                </div>
                <div>
                    <p style={{ display: 'inline', marginRight: '10px' }}>~ See section 3.3 of the iRecords guide.</p>
                </div>
            </div>
            <div style={{ maxWidth: '100%', display: 'flex' }}>
                <div style={{ width: '800px' }}>
                    <CSVReader
                        onDrop={handleOnDrop}
                        onError={handleOnError}
                        addRemoveButton
                        removeButtonColor='#659cef'
                        onRemoveFile={handleOnRemoveFile}
                        onChange={handleChange}
                    >
                        <span>Drop CSV file here or click to upload.</span>
                    </CSVReader>
                </div>
                <Button onClick={handleSync} className='' block style={{ marginLeft: '20px',marginBottom: '20px', backgroundColor: '#3FA7D6', marginTop: '10px', width: '150px', height: '50px' }} variant="info">
                    Import
                </Button>
            </div>
            <hr></hr>
            {loading &&
                <>
                    <p>Do not close this page. The DDD Sync process may take several minutes for longer files.</p>
                    <CircularProgress />
                </>
            }
            { rows.length > 0 &&
                <div style={{ height: '50vh', minHeight: '500px'}}>
                    <h5 style={{paddingBottom: '20px'}}> DDD Sync Results: </h5>
                    <DataGridPro
                        treeData
                        getTreeDataPath={(row) => row.path}
                        groupingColDef={{headerName: 'Data Type'}}
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        columnVisibilityModel={{ id: false }} // Explicitly hide the ID column
                        slots={{ toolbar: GridToolbar }} />
                        
                </div>
            }
            <Snackbar open={alertText != ''} autoHideDuration={30000} onClose={onAlertClose}>
                <MuiAlert sx={{ position: '100%' }} severity={alertSeverity} elevation={6} variant="filled" {...props}>
                    {alertText}
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.25 }}
                    onClick={onAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
        </div>)
    );
}