import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import { Form } from '@formio/react';
import {  APIURL, ONBOARDAPIURL } from '../../../config';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Dialog, DialogContent, DialogTitle, Button } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import SignatureModal from "../../../components/Billing/SignatureCap/SignatureModal";
import moment from 'moment';

const IntakeForm = (props) => {
    const [submitted, setSubmitted] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const [data, setData] = useState({});
    const [errors, setErrors] = useState([]);
    const token = props.match.params.token.split('?')[0];
    const [signatureModal, setSignatureModal] = useState(false);
    const [signCaptured, setSignCaptured] = useState(false);
    const [companyLogo, setCompanyLogo] = useState(null);
    const [companyName, setCompanyName] = useState(null);

    useEffect(() => {
        console.log('Form component loaded', token);
        console.log(ONBOARDAPIURL);
        
        if (!token) {
            window.location.replace('/'); // Redirect to login page
        }
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await axios.get(ONBOARDAPIURL + '/intake', { params: { token: token } });
            let forms = response.data.forms;
            let tokenObj = response.data.token;
            let entityData = JSON.parse(tokenObj.entity_data);
            let companyLogo = response.data.companyLogo;
            console.log('Form data:', forms);
            console.log('Token data:', tokenObj);

            forms = forms.map((form) => {
                if (form.data) {
                    form.completed = true;
                }
                return form;
            });
            
            setData({
                forms: forms,
                task_id: entityData.task_id,
                customer_id: entityData.customer_id,
                customer_name: entityData.customer_name,
                employee_id: tokenObj.created_by,
                signature_required: entityData.signature_required,
                expires_at: tokenObj.expires_at
            });

            setCompanyName(entityData.company_name);            
            
            if (companyLogo) {
                let base64Flag = `data:${companyLogo.type};base64,`;
                let imageStr = arrayBufferToBase64(companyLogo.logo.data);

                setCompanyLogo(base64Flag + imageStr);
            }
        } catch (error) {
            console.log('Error getting data:', error);
            window.location.replace('/'); // Redirect to login page
        }
    }

    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        let bytes = [].slice.call(new Uint8Array(buffer));

        bytes.forEach((b) => binary += String.fromCharCode(b));

        return window.btoa(binary);
    }

    const handleSubmit = async (submission) => {
        try { 
            if (data.signature_required && !signCaptured) {
                setSignatureModal(true);
                return;
            }         
            const response = await axios.post(ONBOARDAPIURL + '/intake/submit', { token: token });
            console.log('Forms submitted:', response);
            setSubmitted(true);
        } catch (error) {
            console.log('Error submitting data:', error);
        }
    }

    const saveForm = async (submission) => {
        let completedForms = data.forms.map((f) => {
            if (f.form_id === selectedForm.form_id) {
                f.completed = true;
                f.data = submission;
            }
            return f;
        });

        const response = await axios.post(ONBOARDAPIURL + '/intake', { token: token, form: { form_id: selectedForm.form_id, data: submission } });

        console.log('Form saved:', response);
                
        setData({
            ...data,
            forms: completedForms
        });
        setOpen(false);
    }

    
    return (
        <Box>
            { (data.forms && companyLogo) &&
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', width: '100%', padding: '20px', borderBottom: '1px solid #ccc' }}>
                        <Box sx={{ flex: 1 }}>
                            <img src={companyLogo} alt='Company Logo' style={{ width: '80px', height: '80px' }} />
                        </Box>
                        <Typography variant='h5' sx={{ flex: 1, textAlign: 'center' }}>{companyName}</Typography>
                        <Typography variant='h6' sx={{ flex: 1, textAlign: 'right' }}>{data.customer_name}</Typography>
                    </Box>
                    <Box sx={{ maxWidth: '800px', margin: 'auto' }}>
                        {submitted ? 
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '80vh' }}>
                                <CheckCircleOutlineIcon sx={{ fontSize: '90px', color: 'green' }} />
                                <Typography variant='h4'>Form(s) submitted successfully</Typography>
                            </Box> :
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: '20px', flex: 1 }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                        <Typography variant='h5'>Intake Forms</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', marginBottom: '20px' }}>
                                        <Typography variant="body1">
                                            Please complete the following forms as part of the intake process. 
                                            You can save your progress and come back later to complete the forms. 
                                            Once all forms are completed, click the "Submit All" button to submit the forms. 
                                            You have before {moment(data.expires_at).format('MMMM Do YYYY')} to submit the forms.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', flex: 1 }}>
                                    { data.forms?.map((form) =>
                                        <Box
                                            key={form.form_id}
                                            onClick={() => {
                                                setSelectedForm(form);
                                                setOpen(true);
                                            }}
                                            sx={{
                                                border: "1px solid black",
                                                borderRadius: "5px",
                                                padding: "5px",
                                                margin: "5px",
                                                cursor: "pointer",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%"
                                            }}
                                        >
                                            <Box>{form.form_name}</Box>
                                            {form.completed ?
                                                <CheckCircleOutlinedIcon /> : 
                                                null
                                            }
                                        </Box>
                                    )}
                                    <Button onClick={handleSubmit} variant='contained' color='primary' sx={{ width: '200px', mx: 'auto', marginTop: '20px' }} disabled={data.forms?.some((f) => !f.completed)}>
                                        Submit All
                                    </Button>
                                </Box>
                                <Box sx={{ flex: 1 }}/>
                            <Box/>
                            <Dialog
                                open={open}
                                onClose={() => setOpen(false)}
                                maxWidth='md'
                                fullWidth
                                scroll='body'
                                disableRestoreFocus
                            >
                                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc' }}>
                                    <div>
                                        {selectedForm?.form_name}
                                    </div>
                                    <div>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    <Form
                                        form={JSON.parse(selectedForm?.json_schema || '{}')}
                                        submission={selectedForm?.data}
                                        onSubmit={saveForm}
                                        onError={(errors) => {
                                            setErrors(errors);
                                        }}
                                    />
                                </DialogContent>
                            </Dialog>
                            <SignatureModal 
                                task={{
                                    cusInfo: [{ cus_id: data.customer_id, cus_name: data.customer_name }],
                                    tas_pro_id: null,
                                    tas_id: data.task_id
                                }}
                                emp_id={data.employee_id}
                                show={signatureModal} 
                                toggle={() => { setSignatureModal(!signatureModal) }}
                                captured={() => { setSignCaptured(true) }}
                            />
                        </Box>
                        }
                    </Box>
                </Box>
            } 
        </Box>
    )
}

export default IntakeForm;